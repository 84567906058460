import { fromJS, List, Map } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeUserProfile,
  normalizeReferralsCount,
  normalizeDepositBonusInfoData,
  normalizeCurrentDepositBonusData,
  normalizeDepositBonusHistoryData,
  normalizeNotificationPrefencences,
  normalizeCommissionHistoryData,
  normalizeReferredUsersData,
  normalizeActivityLogs,
  normalizeWRXBreakdown,
  normalizeWRXUnlockSchedule,
  normalizeWRXUnlockHistory,
  normalizePreferences,
  normalizeCoupons,
  normalizeCoupon,
  normalizeVerificationAggregates
} from '../utils/normalizer/userProfile';
import { normalizePaymentOptionsByProvider } from '../utils/normalizer/common';
import {
  setToLocalStorage,
  removeFromLocalStorage
} from '../utils/localStorageHelpers';
import { CURRENCY, REDEEMED_COUPONS_LIMIT } from '../constants/Core';

const initialState = fromJS({
  login: {
    signIn: {},
    signUp: {},
    oauth: {}
  },
  userData: {
    isFetching: true
  },
  savedEmail: '',
  savedEmailOnce: false,
  activation: {
    isActivating: false,
    isActivatingError: false,
    message: ''
  },
  contest: {
    isFetching: false,
    isFetchingError: false,
    data: null
  },
  wrx: {
    current: {
      isFetching: false,
      isFetchingError: false
    },
    history: {
      isFetching: false,
      isFetchingError: false
    },
    breakdown: {
      isFetching: false,
      isFetchingError: false
    },
    unlockSchedule: {
      isFetching: false,
      isFetchingError: false
    },
    unlockHistory: {
      isFetching: false,
      isFetchingError: false
    }
  },
  depositBonus: {
    info: {
      isFetching: false,
      isFetchingError: false
    },
    current: {
      isFetching: false,
      isFetchingError: false
    },
    history: {
      isFetching: false,
      isFetchingError: false
    }
  },
  paymentOptions: {
    isFetching: false,
    isFetchingError: false,
    data: {},
    format: {
      isFetching: false,
      isFetchingError: false
    },
    add: {}
  },
  preferences: {
    notifications: {
      isFetching: false,
      isFetchingError: false
    },
    fee: {
      isFetching: false,
      isFetchingError: false
    }
  },
  referral: {
    commissionHistory: {
      isFetching: false,
      isFetchingError: false,
      data: []
    },
    referredUsers: {
      isFetching: false,
      isFetchingError: false,
      data: []
    },
    totalEarnings: {
      isFetching: false,
      isFetchingError: false
    }
  },
  activities: {
    isFetching: false,
    isFetchingError: false,
    lastPage: 0
  },
  refreshBinanceKYC: {
    isFetching: false,
    isFetchingError: false,
    data: null,
    message: null
  },
  favouriteMarkets: {
    isFetching: false,
    isFetchingError: false,
    data: {}
  },
  coupons: {
    redeemed: {
      isFetching: false,
      isFetchingError: false,
      data: []
    },
    claim: {
      isPosting: false,
      isPostingError: false,
      data: null
    }
  },
  registerPhone: {
    isPosting: false,
    isPostingError: false,
    message: null
  },
  apiKeys: {
    isFetching: false,
    isFetchingError: false,
    data: []
  },
  validatePassword: {
    isFetching: false,
    isFetchingError: null,
    data: null,
    message: null
  },
  changeEmail: {
    isFetching: false,
    isFetchingError: null,
    data: null,
    message: null
  },
  changeMobile: {
    isFetching: false,
    isFetchingError: null,
    data: null,
    message: null
  },
  verifyMobileChange: {
    isFetching: false,
    isFetchingError: null,
    data: null,
    message: null
  },
  resendEmailOTP: {
    isFetching: false,
    isFetchingError: null,
    data: null,
    message: null
  }
});

export default function(state = initialState, action) {
  let preferenceIndex;

  switch (action.type) {
    case types.SAVE_NEW_EMAIL:
      return state.mergeIn(['userData', 'data'], {
        email: action?.email || ''
      });
    case types.SAVE_NEW_MOBILE:
      return state.mergeIn(['userData', 'data'], {
        mobile: action?.mobile || ''
      });
    case types.OAUTH_LOGIN_INITIATE:
      return state.mergeIn(['login', 'oauth'], {
        isLoginInitiated: true,
        isLoginSuccessful: false,
        isLoginFailed: false
      });
    case types.OAUTH_LOGIN_SUCCESS:
      if (action.auth) {
        // Setting access key and secret key to localstorage for future access to auth credentials.
        setToLocalStorage('accessKey', action.auth.access_key);
        setToLocalStorage('secretKey', action.auth.secret_key);
      }
      return state.mergeIn(['login', 'oauth'], {
        isLoginSuccessful: true
      });
    case types.OAUTH_LOGIN_ERROR:
      return state.mergeIn(['login', 'oauth'], {
        isLoginFailed: true,
        loginFailureMessage: action.message
      });
    case types.OAUTH_LOGIN_VALIDATE_OAUTH_CODE_SUCCESS:
      return state.mergeIn(['login', 'oauth'], {
        email: action.email
      });
    case types.SIGN_IN_INIT:
      return state.mergeIn(['login', 'signIn'], {
        isSending: true,
        isSendingError: false
      });

    case types.SIGN_IN_SUCCESS:
      if (action.data.auth) {
        // Setting access key and secret key to localstorage for future access to auth credentials.
        setToLocalStorage('accessKey', action.data.auth.access_key);
        setToLocalStorage('secretKey', action.data.auth.secret_key);
      }
      return state.mergeIn(['login', 'signIn'], {
        isSending: false,
        isSendingError: false,
        data: action.data
      });

    case types.SIGN_IN_ERROR:
      return state.mergeIn(['login', 'signIn'], {
        isSending: false,
        isSendingError: true,
        error: action.data
      });

    case types.SET_SIGN_IN_ERROR:
      return state.mergeIn(['login', 'signIn'], {
        isSending: false,
        isSendingError: true,
        error: action.data
      });

    case types.SIGN_UP_INIT:
      return state.mergeIn(['login', 'signUp'], {
        isSending: true,
        isSendingError: false
      });

    case types.SIGN_UP_SUCCESS:
      // Setting access key and secret key to localstorage for future access to auth credentials.
      setToLocalStorage('accessKey', action.data.access_key);
      setToLocalStorage('secretKey', action.data.secret_key);
      return state.mergeIn(['login', 'signUp'], {
        isSending: false,
        isSendingError: false,
        data: action.data
      });

    case types.SIGN_UP_ERROR:
      return state.mergeIn(['login', 'signUp'], {
        isSending: false,
        isSendingError: true,
        error: action.data
      });

    case types.SIGN_OUT_INIT:
      return state.mergeIn(['login', 'signOut'], {
        isSending: true,
        isSendingError: false
      });

    case types.SIGN_OUT_COMPLETE:
      return state.mergeIn(['login', 'signOut'], {
        isSending: false,
        isSendingError: false
      });

    case types.FETCH_REFERRAL_CODE_SUCCESS:
      return state.mergeIn(['userData'], {
        referralCode: action.data
      });

    case types.FETCH_REFERRAL_CODE_ERROR:
      return state.mergeIn(['userData'], {
        referralCode: null
      });

    case types.FETCH_REFERRALS_COUNT_SUCCESS:
      return state.mergeIn(['userData'], {
        referralsCount: normalizeReferralsCount(action.data)
      });

    case types.FETCH_REFERRALS_COUNT_ERROR:
      return state.mergeIn(['userData'], {
        referralsCount: null
      });

    case types.FETCH_CONTEST_DATA_INIT:
      return state.mergeIn(['contest'], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_CONTEST_DATA_SUCCESS:
      return state.mergeIn(['contest'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_CONTEST_DATA_ERROR:
      return state.mergeIn(['contest'], {
        isFetching: false,
        isFetchingError: true,
        data: null
      });

    case types.RESET_USER_DATA:
      removeFromLocalStorage('accessKey');
      removeFromLocalStorage('secretKey');
      return state
        .mergeIn(['userData'], {
          isFetching: false,
          isFetchingError: false,
          data: null
        })
        .mergeIn(['paymentOptions'], {
          isFetching: false,
          isFetchingError: false,
          data: null
        })
        .mergeIn(['depositBonus', 'current'], {
          isFetching: false,
          isFetchingError: false,
          data: null
        })
        .mergeIn(['depositBonus', 'history'], {
          isFetching: false,
          isFetchingError: false,
          data: null
        })
        .mergeIn(['wrx', 'breakdown'], {
          isFetching: false,
          isFetchingError: false,
          data: null,
          message: null
        })
        .mergeIn(['wrx', 'unlockSchedule'], {
          isFetching: false,
          isFetchingError: false,
          data: null,
          message: null
        })
        .mergeIn(['wrx', 'unlockHistory'], {
          isFetching: false,
          isFetchingError: false,
          data: null,
          message: null
        });
    case types.FETCH_USER_PROFILE_INIT:
      return state.mergeIn(['userData'], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_USER_PROFILE_SUCCESS:
      return state.mergeIn(['userData'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeUserProfile(action.data)
      });

    case types.VERIFICATION_STAGES_UPDATE:
      return state.mergeIn(['userData', 'data'], {
        ...normalizeVerificationAggregates(action.data)
      });

    case types.FETCH_USER_PROFILE_ERROR:
      return state.mergeIn(['userData'], {
        isFetching: false,
        isFetchingError: true
      });

    case types.SAVE_EMAIL:
      return state.merge({
        savedEmail: action.email,
        savedEmailOnce: true
      });

    case types.RESET_SAVE_EMAIL:
      return state.merge({
        savedEmail: '',
        savedEmailOnce: false
      });

    case types.ACTIVATE_INIT:
      return state.mergeIn(['activation'], {
        isActivating: true,
        isActivatingError: false
      });

    case types.ACTIVATE_SUCCESS:
      return state.mergeIn(['activation'], {
        isActivating: false,
        isActivatingError: false,
        message: action.data
      });

    case types.ACTIVATE_ERROR:
      return state.mergeIn(['activation'], {
        isActivating: false,
        isActivatingError: true,
        message: action.data
      });

    case types.RESET_LOGIN:
      return state.mergeIn(['login', 'signIn'], {
        isSendingError: false,
        error: null
      });
    case types.RESET_SIGNUP:
      return state.mergeIn(['login', 'signUp'], {
        isSendingError: false,
        error: null
      });
    case types.FETCH_WRX_CURRENT_REWARDS_INIT:
      return state.mergeIn(['wrx', 'current'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_WRX_CURRENT_REWARDS_SUCCESS:
      return state.mergeIn(['wrx', 'current'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_WRX_CURRENT_REWARDS_ERROR:
      return state.mergeIn(['wrx', 'current'], {
        isFetching: false,
        isFetchingError: true
      });
    case types.FETCH_WRX_REWARDS_HISTORY_INIT:
      return state.mergeIn(['wrx', 'history'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_WRX_REWARDS_HISTORY_SUCCESS:
      return state.mergeIn(['wrx', 'history'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_WRX_REWARDS_HISTORY_ERROR:
      return state.mergeIn(['wrx', 'history'], {
        isFetching: false,
        isFetchingError: true
      });
    case types.SET_USERNAME_INIT:
      return state.mergeIn(['userData'], {
        isSettingUsername: true,
        isSettingUsernameError: false,
        usernameErrorMessage: ''
      });
    case types.SET_USERNAME_SUCCESS:
      return state
        .setIn(['userData', 'data', 'username'], action.data.username)
        .mergeIn(['userData'], {
          isSettingUsername: false,
          isSettingUsernameError: false,
          usernameErrorMessage: ''
        });
    case types.SET_USERNAME_ERROR:
      return state.mergeIn(['userData'], {
        isSettingUsername: false,
        isSettingUsernameError: true,
        usernameErrorMessage: action.data.message
      });
    case types.SET_PREFERRED_QUOTE_CURRENCY_INIT:
      return state.mergeIn(['userData'], {
        isSettingPreferredQuoteCurrency: true,
        isSettingPreferredQuoteCurrencyError: false
      });
    case types.SET_PREFERRED_QUOTE_CURRENCY_SUCCESS:
      return state
        .setIn(
          ['userData', 'data', 'preferredQuoteCurrency'],
          action.data.preferredQuoteCurrency
        )
        .mergeIn(['userData'], {
          isSettingPreferredQuoteCurrency: false,
          isSettingPreferredQuoteCurrencyError: false,
          isSettingPreferredQuoteCurrencyErrorMessage: ''
        });
    case types.SET_PREFERRED_QUOTE_CURRENCY_ERROR:
      return state.mergeIn(['userData'], {
        isSettingPreferredQuoteCurrency: false,
        isSettingPreferredQuoteCurrencyError: true,
        isSettingPreferredQuoteCurrencyErrorMessage: action.message
      });
    case types.FETCH_PAYMENT_OPTIONS_FORMAT_INIT:
      return state.mergeIn(['paymentOptions', 'format'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_PAYMENT_OPTIONS_FORMAT_SUCCESS:
      return state.mergeIn(['paymentOptions', 'format'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_PAYMENT_OPTIONS_FORMAT_ERROR:
      return state.mergeIn(['paymentOptions', 'format'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_PAYMENT_OPTIONS_INIT:
      return state.mergeIn(['paymentOptions'], {
        isFetching: true,
        isFetchingError: false,
        message: null
      });
    case types.FETCH_PAYMENT_OPTIONS_SUCCESS:
      return state.mergeIn(['paymentOptions'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizePaymentOptionsByProvider(action.data)
      });
    case types.FETCH_PAYMENT_OPTIONS_ERROR:
      return state.mergeIn(['paymentOptions'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.ADD_PAYMENT_OPTION_INIT:
      return state.mergeIn(['paymentOptions', 'add'], {
        isAddingPO: true,
        isAddingPOError: false
      });
    case types.ADD_PAYMENT_OPTION_SUCCESS:
      return state.mergeIn(['paymentOptions', 'add'], {
        isAddingPO: false,
        isAddingPOError: false,
        ...action.data
      });
    case types.ADD_PAYMENT_OPTION_ERROR:
      return state.mergeIn(['paymentOptions', 'add'], {
        isAddingPO: false,
        isAddingPOError: true,
        message: action.message
      });

    case types.REMOVE_PAYMENT_OPTION_INIT: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isRemoving: true,
          isRemovingError: false
        }
      );
    }
    case types.REMOVE_PAYMENT_OPTION_SUCCESS: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isRemoving: false,
          isRemovingError: false
        }
      );
    }
    case types.REMOVE_PAYMENT_OPTION_ERROR: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isRemoving: false,
          isRemovingError: true,
          removingPOErrorMessage: action.message
        }
      );
    }
    case types.RESET_PAYMENT_OPTIONS_2FA_DATA:
      return state.mergeIn(['paymentOptions', 'add'], {
        '2fa': null
      });
    case types.RESET_ADD_PAYMENT_OPTIONS_ERROR:
      return state.mergeIn(['paymentOptions', 'add'], {
        isAddingPO: false,
        isAddingPOError: false,
        message: null
      });
    case types.VERIFY_PAYMENT_OPTION_INIT: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isVerifying: true,
          isVerifyingError: false
        }
      );
    }
    case types.VERIFY_PAYMENT_OPTION_SUCCESS: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isVerifying: false,
          isVerifyingError: false,
          ...action.data
        }
      );
    }
    case types.VERIFY_PAYMENT_OPTION_ERROR: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        [
          'paymentOptions',
          'data',
          CURRENCY.INR,
          action.provider,
          paymentOptionIndex
        ],
        {
          isVerifying: false,
          isVerifyingError: true,
          message: action.message
        }
      );
    }

    case types.SET_DEFAULT_PAYMENT_OPTION_INIT: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isSettingDefault: true,
          isSettingDefaultError: false
        }
      );
    }
    case types.SET_DEFAULT_PAYMENT_OPTION_SUCCESS: {
      const { currency, provider, id, data } = action;
      return state.updateIn(
        ['paymentOptions', 'data', currency, provider],
        paymentOptions =>
          paymentOptions.map(paymentOption => {
            if (paymentOption.get('id') === id) {
              return fromJS({
                ...data,
                isSettingDefault: false,
                isSettingDefaultError: false
              });
            } else {
              return paymentOption.set('isDefault', false);
            }
          })
      );
    }
    case types.SET_DEFAULT_PAYMENT_OPTION_ERROR: {
      const { currency, provider, id, message } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);

      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isSettingDefault: false,
          isSettingDefaultError: true,
          setDefaultErrorMessage: message
        }
      );
    }

    case types.UPDATE_PAYMENT_OPTION_ALIAS_INIT: {
      const { currency, provider, id } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);
      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isEditingAlias: true,
          isEditingAliasError: false
        }
      );
    }
    case types.UPDATE_PAYMENT_OPTION_ALIAS_SUCCESS: {
      const { currency, provider, id, data } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);
      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isEditingAlias: false,
          isEditingAliasError: false,
          ...data
        }
      );
    }
    case types.UPDATE_PAYMENT_OPTION_ALIAS_ERROR: {
      const { currency, provider, id, message } = action;
      const paymentOptionIndex = state
        .getIn(['paymentOptions', 'data', currency, provider])
        .findIndex(po => po.get('id') === id);
      return state.mergeIn(
        ['paymentOptions', 'data', currency, provider, paymentOptionIndex],
        {
          isEditingAlias: false,
          isEditingAliasError: true,
          editingAliasError: message
        }
      );
    }

    case types.FETCH_DEPOSIT_BONUS_INFO_INIT:
      return state.mergeIn(['depositBonus', 'info'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_DEPOSIT_BONUS_INFO_SUCCESS:
      return state.mergeIn(['depositBonus', 'info'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeDepositBonusInfoData(action.data)
      });
    case types.FETCH_DEPOSIT_BONUS_INFO_ERROR:
      return state.mergeIn(['depositBonus', 'info'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_DEPOSIT_BONUS_CURRENT_INIT:
      return state.mergeIn(['depositBonus', 'current'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_DEPOSIT_BONUS_CURRENT_SUCCESS:
      return state.mergeIn(['depositBonus', 'current'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeCurrentDepositBonusData(action.data)
      });
    case types.FETCH_DEPOSIT_BONUS_CURRENT_ERROR:
      return state.mergeIn(['depositBonus', 'current'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_DEPOSIT_BONUS_HISTORY_INIT: {
      return state.mergeIn(['depositBonus', 'history'], {
        isFetching: true,
        isFetchingError: false
      });
    }
    case types.FETCH_DEPOSIT_BONUS_HISTORY_SUCCESS:
      return state.mergeIn(['depositBonus', 'history'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeDepositBonusHistoryData(action.data)
      });
    case types.FETCH_DEPOSIT_BONUS_HISTORY_ERROR:
      return state.mergeIn(['depositBonus', 'history'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.FETCH_NOTIFICATION_PREFERENCES_INIT:
      return state.mergeIn(['preferences', 'notifications'], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_NOTIFICATION_PREFERENCES_SUCCESS:
      return state.mergeIn(['preferences', 'notifications'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeNotificationPrefencences(action.data)
      });

    case types.FETCH_NOTIFICATION_PREFERENCES_ERROR:
      return state.mergeIn(['preferences', 'notifications'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.SET_NOTIFICATION_PREFERENCES_INIT:
      return state.mergeIn(
        ['preferences', 'notifications', 'data', action.preferenceId],
        {
          isUpdating: true
        }
      );
    case types.SET_NOTIFICATION_PREFERENCES_SUCCESS: {
      return state.mergeIn(
        ['preferences', 'notifications', 'data', action.preferenceId],
        {
          enabled: action.enabled,
          frequency: action.frequency,
          isUpdating: false
        }
      );
    }
    case types.SET_NOTIFICATION_PREFERENCES_ERROR:
      return state.mergeIn(
        ['preferences', 'notifications', 'data', action.preferenceId],
        {
          isUpdating: false,
          isUpdatingError: true
        }
      );

    case types.FETCH_COMMISSION_HISTORY_INIT: {
      return state.mergeIn(['referral', 'commissionHistory'], {
        isFetching: true,
        isFetchingError: false
      });
    }
    case types.FETCH_COMMISSION_HISTORY_SUCCESS: {
      const currentData = action.loadMore
        ? state.getIn(['referral', 'commissionHistory', 'data'], [])
        : [];
      /*
        Verified that currentData is Immutable and normalize action.data is normal Array
        But mergeIn fn converts both into Immutable maps
      */
      return state.mergeIn(['referral', 'commissionHistory'], {
        isFetching: false,
        isFetchingError: false,
        data: [...currentData, ...normalizeCommissionHistoryData(action.data)],
        isPageEnd: !action.data?.length || action.data?.length < action.limit
      });
    }
    case types.FETCH_COMMISSION_HISTORY_ERROR:
      return state.mergeIn(['referral', 'commissionHistory'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_REFERRED_USERS_INIT: {
      return state.mergeIn(['referral', 'referredUsers'], {
        isFetching: true,
        isFetchingError: false
      });
    }
    case types.FETCH_REFERRED_USERS_SUCCESS: {
      const currentData = action.loadMore
        ? state.getIn(['referral', 'referredUsers', 'data'], [])
        : [];
      /*
        Verified that currentData is Immutable and normalize action.data is normal Array
        But mergeIn fn converts both into Immutable maps
      */
      return state.mergeIn(['referral', 'referredUsers'], {
        isFetching: false,
        isFetchingError: false,
        data: [...currentData, ...normalizeReferredUsersData(action.data)],
        isPageEnd: !action.data?.length || action.data?.length < action.limit
      });
    }
    case types.FETCH_REFERRED_USERS_ERROR:
      return state.mergeIn(['referral', 'referredUsers'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_TOTAL_REFERRAL_EARNINGS_INIT: {
      return state.mergeIn(['referral', 'totalEarnings'], {
        isFetching: true,
        isFetchingError: false
      });
    }
    case types.FETCH_TOTAL_REFERRAL_EARNINGS_SUCCESS:
      return state.mergeIn(['referral', 'totalEarnings'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_TOTAL_REFERRAL_EARNINGS_ERROR:
      return state.mergeIn(['referral', 'totalEarnings'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.FETCH_USER_ACTIVITY_LOGS_INIT:
      return state.mergeIn(['activities'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_USER_ACTIVITY_LOGS_SUCCESS: {
      const normalizedActivities = normalizeActivityLogs(action.data);
      const hasMore =
        normalizedActivities && normalizedActivities.length === action.pageSize;
      return state.mergeIn(['activities'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizedActivities,
        lastPage: action.page,
        hasMore,
        pageSize: action.pageSize
      });
    }
    case types.FETCH_USER_ACTIVITY_LOGS_ERROR:
      return state.mergeIn(['activities'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.REQUEST_TRADING_REPORT_INIT:
      return state.mergeIn(['tradingReport'], {
        isRequesting: true,
        isRequestingError: false
      });
    case types.REQUEST_TRADING_REPORT_SUCCESS:
      return state.mergeIn(['tradingReport'], {
        isRequesting: false,
        isRequestingError: false,
        data: action.data
      });
    case types.REQUEST_TRADING_REPORT_ERROR:
      return state.mergeIn(['tradingReport'], {
        isRequesting: false,
        isRequestingError: true,
        message: action.message
      });
    case types.REQUEST_GST_INVOICE_INIT:
      return state.mergeIn(['gstInvoice'], {
        isRequesting: true,
        isRequestingError: false
      });
    case types.REQUEST_GST_INVOICE_SUCCESS:
      return state.mergeIn(['gstInvoice'], {
        isRequesting: false,
        isRequestingError: false,
        data: action.data
      });
    case types.REQUEST_GST_INVOICE_ERROR:
      return state.mergeIn(['gstInvoice'], {
        isRequesting: false,
        isRequestingError: true,
        message: action.message
      });
    case types.FETCH_WRX_BREAKDOWN_INIT:
      return state.mergeIn(['wrx', 'breakdown'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    case types.FETCH_WRX_BREAKDOWN_SUCCESS:
      return state.mergeIn(['wrx', 'breakdown'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeWRXBreakdown(action.data)
      });
    case types.FETCH_WRX_BREAKDOWN_ERROR:
      return state.mergeIn(['wrx', 'breakdown'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_WRX_UNLOCK_SCHEDULE_INIT:
      return state.mergeIn(['wrx', 'unlockSchedule'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    case types.FETCH_WRX_UNLOCK_SCHEDULE_SUCCESS:
      return state.mergeIn(['wrx', 'unlockSchedule'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeWRXUnlockSchedule(action.data)
      });
    case types.FETCH_WRX_UNLOCK_SCHEDULE_ERROR:
      return state.mergeIn(['wrx', 'unlockSchedule'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.FETCH_WRX_UNLOCK_HISTORY_INIT:
      return state.mergeIn(['wrx', 'unlockHistory'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_WRX_UNLOCK_HISTORY_SUCCESS:
      return state.mergeIn(['wrx', 'unlockHistory'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeWRXUnlockHistory(action.data)
      });
    case types.FETCH_WRX_UNLOCK_HISTORY_ERROR:
      return state.mergeIn(['wrx', 'unlockHistory'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.REFRESH_BINANCE_KYC_INIT:
      return state.mergeIn([
        'refreshBinanceKYC',
        {
          isFetching: true,
          isFetchingError: false
        }
      ]);
    case types.REFRESH_BINANCE_KYC_SUCCESS:
      return state.mergeIn([
        'refreshBinanceKYC',
        {
          isFetching: false,
          isFetchingError: false,
          data: action.data
        }
      ]);
    case types.REFRESH_BINANCE_KYC_ERROR:
      return state.mergeIn([
        'refreshBinanceKYC',
        {
          isFetching: false,
          isFetchingError: true,
          message: action.message
        }
      ]);
    case types.FETCH_PREFERENCES_INIT:
      return state.mergeIn(['preferences', action.category], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_PREFERENCES_SUCCESS:
      return state.mergeIn(['preferences', action.category], {
        isFetching: false,
        isFetchingError: false,
        data: normalizePreferences(action.category, action.data)
      });

    case types.FETCH_PREFERENCES_ERROR:
      return state.mergeIn(['preferences', action.category], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.SET_PREFERENCES_INIT:
      preferenceIndex = state
        .getIn(['preferences', action.category, 'data'])
        .findIndex(preference => preference.get('id') === action.id);

      return state.mergeIn(
        ['preferences', action.category, 'data', preferenceIndex],
        {
          isUpdating: true
        }
      );
    case types.SET_PREFERENCES_SUCCESS:
      preferenceIndex = state
        .getIn(['preferences', action.category, 'data'])
        .findIndex(preference => preference.get('id') === action.id);

      return state.mergeIn(
        ['preferences', action.category, 'data', preferenceIndex],
        {
          isUpdating: true,
          value: action.value
        }
      );
    case types.SET_PREFERENCES_ERROR:
      preferenceIndex = state
        .getIn(['preferences', action.category, 'data'])
        .findIndex(preference => preference.get('id') === action.id);

      return state.mergeIn(
        ['preferences', action.category, 'data', preferenceIndex],
        {
          isUpdating: false,
          isUpdatingError: true
        }
      );
    case types.LOAD_FAVOURITE_MARKETS_INIT:
      return state.mergeIn(['favouriteMarkets'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.LOAD_FAVOURITE_MARKETS_SUCCESS:
      return state.mergeIn(['favouriteMarkets'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.LOAD_FAVOURITE_MARKETS_ERROR:
      return state.mergeIn(['favouriteMarkets'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.REMOVE_FAVOURITE_MARKET: {
      let existingMarkets = state.getIn(
        ['favouriteMarkets', 'data', action.marketType],
        new List()
      );
      if (existingMarkets.includes(action.market)) {
        existingMarkets = existingMarkets.filter(
          item => item !== action.market
        );
      }
      return state.mergeIn(
        ['favouriteMarkets', 'data'],
        new Map({ [action.marketType]: existingMarkets })
      );
    }
    case types.ADD_FAVOURITE_MARKET: {
      let existingMarkets = state.getIn(
        ['favouriteMarkets', 'data', action.marketType],
        new List()
      );
      if (!existingMarkets.includes(action.market)) {
        existingMarkets = existingMarkets.push(action.market);
      }
      return state.mergeIn(
        ['favouriteMarkets', 'data'],
        new Map({ [action.marketType]: existingMarkets })
      );
    }
    case types.FETCH_REDEEMED_COUPONS_INIT: {
      const initData = {
        isFetching: true,
        isFetchingError: false
      };

      if (action.isFirstLoad) {
        initData.data = [];
      }
      return state.mergeIn(['coupons', 'redeemed'], initData);
    }
    case types.FETCH_REDEEMED_COUPONS_SUCCESS: {
      const { coupons } = action.data;
      const hasMore = coupons.length === REDEEMED_COUPONS_LIMIT;
      const newState = state.mergeIn(['coupons', 'redeemed'], {
        isFetching: false,
        isFetchingError: false,
        hasMore,
        page: action.page
      });

      const currentData = newState.getIn(['coupons', 'redeemed', 'data']);
      if (action.page !== 1 && currentData) {
        return newState.updateIn(['coupons', 'redeemed', 'data'], data =>
          data.concat(fromJS(normalizeCoupons(coupons)))
        );
      }
      return newState.mergeIn(['coupons', 'redeemed'], {
        data: normalizeCoupons(coupons)
      });
    }
    case types.FETCH_REDEEMED_COUPONS_ERROR:
      return state.mergeIn(['coupons', 'redeemed'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.CLAIM_COUPONS_INIT:
      return state.mergeIn(['coupons', 'claim'], {
        isPosting: true,
        isPostingError: false
      });
    case types.CLAIM_COUPONS_SUCCESS: {
      const newState = state.mergeIn(['coupons', 'claim'], {
        isPosting: false,
        isPostingError: false
      });
      return newState.updateIn(['coupons', 'redeemed', 'data'], data =>
        data.unshift(fromJS(normalizeCoupon(action.data)))
      );
    }
    case types.CLAIM_COUPONS_ERROR:
      return state.mergeIn(['coupons', 'claim'], {
        isPosting: false,
        isPostingError: true,
        message: action.message
      });

    case types.REGISTER_PHONE_INIT:
      return state.mergeIn(['registerPhone'], {
        isPosting: true,
        isPostingError: false
      });
    case types.REGISTER_PHONE_SUCCESS:
      return state.mergeIn(['registerPhone'], {
        isPosting: false,
        isPostingError: false
      });
    case types.REGISTER_PHONE_ERROR:
      return state.mergeIn(['registerPhone'], {
        isPosting: false,
        isPostingError: true,
        message: action.message
      });
    case types.FETCH_API_KEYS_INIT:
      return state.mergeIn(['apiKeys'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_API_KEYS_SUCCESS:
      return state.mergeIn(['apiKeys'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data.apiKeys || []
      });
    case types.FETCH_API_KEYS_ERROR:
      return state.mergeIn(['apiKeys'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.CREATE_NEW_API_KEY_INIT:
      return state.mergeIn(['apiKeys'], {
        isCreating: true,
        isCreatingError: false
      });
    case types.CREATE_NEW_API_KEY_SUCCESS:
      return state.mergeIn(['apiKeys'], {
        isCreating: false,
        isCreatingError: false,
        interstitialKeyData: action.keyData
      });
    case types.CREATE_NEW_API_KEY_ERROR:
      return state.mergeIn(['apiKeys'], {
        isCreating: false,
        isCreatingError: true,
        message: action.message
      });
    case types.CREATE_NEW_API_KEY_RESET:
      return state.mergeIn(['apiKeys'], {
        isCreating: false,
        isCreatingError: false,
        message: null,
        interstitialKeyData: null
      });
    case types.SET_NEW_API_KEY_IN_LIST: {
      const privateKey = state.getIn([
        'apiKeys',
        'interstitialKeyData',
        'privateKey'
      ]);
      return state.updateIn(['apiKeys', 'data'], apiKeys => {
        const apiKeyData = { ...action.data.apiKey, privateKey };
        return apiKeys.push(fromJS(apiKeyData));
      });
    }
    case types.UPDATE_API_KEY_INIT: {
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === action.key);
      return state.mergeIn(['apiKeys', 'data', apiKeyIndex], {
        isUpdating: true,
        isUpdatingError: false
      });
    }

    case types.UPDATE_API_KEY_SUCCESS: {
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === action.key);
      return state.mergeIn(['apiKeys', 'data', apiKeyIndex], {
        isUpdating: false,
        isUpdatingError: false
      });
    }

    case types.UPDATE_API_KEY_ERROR: {
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === action.key);
      return state.mergeIn(['apiKeys', 'data', apiKeyIndex], {
        isUpdating: false,
        isUpdatingError: true,
        updationErrorMessage: action.message
      });
    }
    case types.UPDATE_API_KEY_IN_LIST: {
      const { apiKey: newApiKey } = action.data;
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === newApiKey.apiKey);
      return state.setIn(['apiKeys', 'data', apiKeyIndex], fromJS(newApiKey));
    }
    case types.DELETE_API_KEY_INIT: {
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === action.key);
      return state.mergeIn(['apiKeys', 'data', apiKeyIndex], {
        isDeleting: true,
        isDeletingError: false
      });
    }
    case types.DELETE_API_KEY_SUCCESS: {
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === action.key);
      const newState = state.updateIn(['apiKeys', 'data'], apiKeys =>
        apiKeys.delete(apiKeyIndex)
      );
      return newState;
    }
    case types.DELETE_API_KEY_ERROR: {
      const apiKeyIndex = state
        .getIn(['apiKeys', 'data'])
        .findIndex(apiKey => apiKey.get('apiKey') === action.key);
      return state.mergeIn(['apiKeys', 'data', apiKeyIndex], {
        isDeleting: false,
        isDeletingError: true,
        deletionErrorMessage: action.message
      });
    }
    case types.DELETE_ALL_API_KEYS_INIT:
      return state.mergeIn(['apiKeys'], {
        isDeletingAll: true,
        isDeletingAllError: false
      });
    case types.DELETE_ALL_API_KEYS_SUCCESS:
      return state.mergeIn(['apiKeys'], {
        isDeletingAll: false,
        isDeletingAllError: false,
        data: []
      });
    case types.DELETE_ALL_API_KEYS_ERROR:
      return state.mergeIn(['apiKeys'], {
        isDeletingAll: false,
        isDeletingAllError: true,
        deletionErrorMessage: action.message
      });
    case types.FETCH_VALIDATE_PASSWORD_INIT:
      return state.mergeIn(['validatePassword'], {
        isFetching: true
      });
    case types.FETCH_VALIDATE_PASSWORD_SUCCESS:
      return state.mergeIn(['validatePassword'], {
        isFetching: false,
        data: action.data
      });
    case types.FETCH_VALIDATE_PASSWORD_ERROR:
      return state.mergeIn(['validatePassword'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_CHANGE_EMAIL_INIT:
      return state.mergeIn(['changeEmail'], {
        isFetching: true
      });
    case types.FETCH_CHANGE_EMAIL_SUCCESS:
      return state.mergeIn(['changeEmail'], {
        isFetching: false,
        data: action.data
      });
    case types.FETCH_CHANGE_EMAIL_ERROR:
      return state.mergeIn(['changeEmail'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_CHANGE_MOBILE_INIT:
      return state.mergeIn(['changeMobile'], {
        isFetching: true
      });
    case types.FETCH_CHANGE_MOBILE_SUCCESS:
      return state.mergeIn(['changeMobile'], {
        isFetching: false,
        data: action.data
      });
    case types.FETCH_CHANGE_MOBILE_ERROR:
      return state.mergeIn(['changeMobile'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_VERIFY_MOBILE_CHANGE_INIT:
      return state.mergeIn(['verifyMobileChange'], {
        isFetching: true
      });
    case types.FETCH_VERIFY_MOBILE_CHANGE_SUCCESS:
      return state.mergeIn(['verifyMobileChange'], {
        isFetching: false,
        data: action.data
      });
    case types.FETCH_VERIFY_MOBILE_CHANGE_ERROR:
      return state.mergeIn(['verifyMobileChange'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_RESEND_EMAIL_OTP_INIT:
      return state.mergeIn(['resendEmailOTP'], {
        isFetching: true
      });
    case types.FETCH_RESEND_EMAIL_OTP_SUCCESS:
      return state.mergeIn(['resendEmailOTP'], {
        isFetching: false,
        data: action.data
      });
    case types.FETCH_RESEND_EMAIL_OTP_ERROR:
      return state.mergeIn(['resendEmailOTP'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    default:
      return state;
  }
}
