import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

//the container which will trigger the tooltip defined above ^
const TooltipContainer = ({
  tooltipId,
  content,
  trigger,
  placement,
  delay,
  tooltipClassName,
  ...props
}) => {
  return (
    <OverlayTrigger
      trigger={trigger || ['hover']}
      placement={placement || 'bottom'}
      delayShow={delay || 300}
      overlay={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Popover className={tooltipClassName || 'close-popover'} id={tooltipId}>
          {/* the actual tooltip shown. It needs to be along with OverlayTrigger to calculate position dynamically */}
          {content}
        </Popover>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default TooltipContainer;
