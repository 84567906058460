import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeTransactionHistory,
  normalizeFunds,
  normalizeLiveWithdrawal,
  normalizeLiveDeposit,
  updateFundsStatus,
  updateFunds,
  normalizeAssetTransferCurrencies,
  normalizeSingleAssetTransfer,
  normalizeGatewayDeposit,
  normalizeCurrencyNetworks,
  normalizePortfolio
} from '../utils/normalizer/transfers';
import { CURRENCY, TRANSACTION_STATES } from '../constants/Core';

const initialState = fromJS({
  deposit: {
    addressData: {},
    gateway: {}
  },
  bankDetails: {
    data: null
  },
  withdrawal: {},
  transferRequest: {
    isSubmitting: false,
    isSubmittingError: false,
    data: null
  },
  history: {},
  funds: {
    isFetching: false,
    isFetchingError: false,
    isFetchedOnce: false
  },
  assetTransferCurrencies: {},
  initiatePaymentGatewayDeposit: {
    isFetching: false,
    isFetchingError: false,
    data: null,
    message: null
  },
  dusts: {
    isFetching: false,
    isFetchingError: false,
    message: null,
    data: null
  },
  convertDust: {
    isPosting: false,
    isPostingError: false,
    data: null,
    message: null
  },
  currencyNetworks: {
    isFetching: false,
    isFetchingError: false,
    message: null,
    data: null
  },
  savedAddress: {
    data: {}
  },
  addSavedAddress: {
    isSubmitting: false,
    isSubmittingError: false,
    data: null
  },
  portfolio: {
    isFetching: false,
    isFetchingError: false,
    isFetchedOnce: false
  }
});

export default function(state = initialState, action) {
  const address = {};
  let oldFundsData;
  let newFundsData;
  let oldCurrencyNetworksData;
  switch (action.type) {
    case types.BUILD_DEPOSIT_ADDRESS_SCHEMA:
      action.currency.forEach(item => {
        address[item] = {
          isAddressFetching: false,
          isAddressFetchingError: false,
          data: {
            address: null,
            tag: null
          }
        };
      });
      return state.mergeIn(['deposit', 'addressData'], fromJS(address));

    case types.FETCH_DEPOSIT_ADDRESS_INIT:
      return state.mergeIn(
        ['deposit', 'addressData', action.currency],
        fromJS({
          isAddressFetching: true,
          isAddressFetchingError: false,
          data: null,
          errorMessage: null
        })
      );

    case types.FETCH_DEPOSIT_ADDRESS_SUCCESS:
      return state.mergeIn(
        ['deposit', 'addressData', action.currency],
        fromJS({
          isAddressFetching: false,
          isAddressFetchingError: false,
          data: action.data
        })
      );

    case types.FETCH_DEPOSIT_ADDRESS_ERROR:
      return state.mergeIn(
        ['deposit', 'addressData', action.currency],
        fromJS({
          isAddressFetching: false,
          isAddressFetchingError: true,
          errorMessage: action.data
        })
      );

    case types.SUBMIT_WITHDRAWAL_INIT:
      return state.mergeIn(['funds', 'data', action.currency, 'withdrawal'], {
        isSaving: true,
        isSavingError: false
      });

    case types.SUBMIT_WITHDRAWAL_SUCCESS:
      return state.mergeIn(['funds', 'data', action.currency, 'withdrawal'], {
        isSaving: false,
        isSavingError: false,
        data: action.data,
        error: null
      });

    case types.SUBMIT_WITHDRAWAL_ERROR:
      return state.mergeIn(['funds', 'data', action.currency, 'withdrawal'], {
        isSaving: false,
        isSavingError: true,
        error: action.error,
        data: null
      });

    case types.FETCH_ADDRESS_BOOKS_INIT:
      return state.mergeIn(
        ['savedAddress', 'data', action.currency],
        fromJS({
          isSavedAddressFetching: true,
          isSavedAddressError: false,
          data: null,
          errorMessage: null
        })
      );

    case types.FETCH_ADDRESS_BOOKS_SUCCESS:
      return state.mergeIn(
        ['savedAddress', 'data', action.currency],
        fromJS({
          isSavedAddressFetching: false,
          isSavedAddressError: false,
          data: action.data
        })
      );

    case types.FETCH_ADDRESS_BOOKS_ERROR:
      return state.mergeIn(
        ['savedAddress', 'data', action.currency],
        fromJS({
          isSavedAddressFetching: false,
          isSavedAddressError: true,
          errorMessage: action.message
        })
      );

    case types.ADD_SAVED_ADDRESS_INIT:
      return state.mergeIn(['addSavedAddress'], {
        isSubmitting: true,
        isSubmittingError: false,
        data: null
      });
    case types.ADD_SAVED_ADDRESS_SUCCESS:
      return state
        .mergeIn(
          ['savedAddress', 'data', action.data.currency],
          fromJS({
            data: [
              ...(state
                .getIn(['savedAddress', 'data', action.data.currency, 'data'])
                ?.toJSON() || []),
              action.data
            ]
          })
        )
        .mergeIn(['addSavedAddress'], {
          isSubmitting: false,
          isSubmittingError: false,
          data: action.data
        });
    case types.ADD_SAVED_ADDRESS_ERROR:
      return state.mergeIn(['addSavedAddress'], {
        isSubmitting: false,
        isSubmittingError: true,
        message: action.message
      });
    case types.ADD_SAVED_ADDRESS_RESET:
      return state.mergeIn(['addSavedAddress'], {
        isSubmitting: false,
        isSubmittingError: false,
        data: null,
        message: null
      });

    case types.FETCH_BANK_DETAILS_INIT:
      return state.mergeIn(['bankDetails'], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_BANK_DETAILS_SUCCESS:
      return state.mergeIn(['bankDetails'], {
        isFetching: false,
        isFetchingError: false,
        error: null,
        data: action.data
      });

    case types.FETCH_BANK_DETAILS_ERROR:
      return state.mergeIn(['bankDetails'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.SUBMIT_BANK_DETAILS_INIT:
      return state.mergeIn(['bankDetails'], {
        isSaving: true,
        isSavingError: false
      });

    case types.SUBMIT_BANK_DETAILS_SUCCESS:
      return state.mergeIn(['bankDetails'], {
        isSaving: false,
        isSavingError: false,
        error: null
      });

    case types.SUBMIT_BANK_DETAILS_ERROR:
      return state.mergeIn(['bankDetails'], {
        isSaving: false,
        isSavingError: true,
        error: action.error
      });

    case types.SUBMIT_DEPOSIT_INIT:
      return state.mergeIn(['funds', 'data', action.currency, 'deposit'], {
        isSaving: true,
        isSavingError: false
      });

    case types.SUBMIT_DEPOSIT_SUCCESS:
      return state.mergeIn(['funds', 'data', action.currency, 'deposit'], {
        isSaving: false,
        isSavingError: false,
        data: action.data,
        error: null
      });

    case types.SUBMIT_DEPOSIT_ERROR:
      return state.mergeIn(['funds', 'data', action.currency, 'deposit'], {
        isSaving: false,
        isSavingError: true,
        error: action.error,
        data: null
      });

    case types.RESET_DEPOSIT_DATA:
      return state.mergeIn(['funds', 'data', action.currency, 'deposit'], {
        isSaving: false,
        isSavingError: false,
        error: null,
        data: null
      });

    case types.RESET_WITHDRAWAL_DATA:
      return state.mergeIn(['funds', 'data', action.currency, 'withdrawal'], {
        isSaving: false,
        isSavingError: false,
        error: null,
        data: null
      });

    case types.INR_WITHDRAWAL_INIT:
      // FIXME: Refactor submit_withdraw_init action to be generic wrt any coin
      return state.mergeIn(['funds', 'data', CURRENCY.INR, 'withdrawal'], {
        isSaving: true,
        isSavingError: false
      });

    case types.INR_WITHDRAWAL_SUCCESS:
      return state.mergeIn(['funds', 'data', CURRENCY.INR, 'withdrawal'], {
        isSaving: false,
        isSavingError: false,
        data: action.data,
        error: null
      });

    case types.INR_WITHDRAWAL_ERROR:
      return state.mergeIn(['funds', 'data', CURRENCY.INR, 'withdrawal'], {
        isSaving: false,
        isSavingError: true,
        error: action.error,
        data: null
      });

    case types.FETCH_TRANSACTION_HISTORY_INIT:
      return state.mergeIn(
        ['history'],
        fromJS({
          isFetching: true,
          isFetchingError: false
        })
      );

    case types.FETCH_TRANSACTION_HISTORY_SUCCESS:
      return state.mergeIn(
        ['history'],
        fromJS({
          isFetching: false,
          isFetchingError: false,
          data: normalizeTransactionHistory(action.data)
        })
      );

    case types.FETCH_TRANSACTION_HISTORY_ERROR:
      return state.mergeIn(
        ['history'],
        fromJS({
          isFetching: false,
          isFetchingError: true,
          errorMessage: action.data
        })
      );

    case types.FETCH_LIVE_WITHDRAWAL:
      oldFundsData = state.getIn(['history', 'data']).toJSON();
      newFundsData = normalizeLiveWithdrawal(action.data, oldFundsData);
      return state.mergeIn(
        ['history'],
        fromJS({
          data: newFundsData
        })
      );

    case types.FETCH_LIVE_DEPOSIT:
      oldFundsData = state.getIn(['history', 'data']).toJSON();
      newFundsData = normalizeLiveDeposit(action.data, oldFundsData);
      return state.mergeIn(
        ['history'],
        fromJS({
          data: newFundsData
        })
      );

    case types.UPDATE_FUNDS_STATUS:
      oldFundsData = state.getIn(['history', 'data']).toJSON();
      newFundsData = updateFundsStatus(action.data, oldFundsData);
      return state.mergeIn(
        ['history'],
        fromJS({
          data: newFundsData
        })
      );

    case types.FETCH_FUNDS_INIT:
      return state.mergeIn(
        ['funds'],
        fromJS({
          isFetching: true,
          isFetchingError: false,
          isFetchedOnce: false
        })
      );

    case types.FETCH_FUNDS_SUCCESS:
      return state.mergeIn(
        ['funds'],
        fromJS({
          isFetching: false,
          isFetchingError: false,
          isFetchedOnce: true,
          data: normalizeFunds(action.data),
          originalData: action.data
        })
      );

    case types.FETCH_FUNDS_ERROR:
      return state.mergeIn(
        ['funds'],
        fromJS({
          isFetching: false,
          isFetchingError: true,
          isFetchedOnce: false,
          errorMessage: action.data
        })
      );

    case types.UPDATE_FUNDS:
      oldFundsData = state.getIn(['funds', 'data']);
      newFundsData = updateFunds(action.data, oldFundsData);
      return state.mergeIn(['funds', 'data'], fromJS(newFundsData));

    case types.RESET_TRANSFER_DATA:
      return state.merge({
        deposit: {
          addressData: {}
        },
        withdrawal: {},
        history: {}
      });
    case types.RESET_FUNDS:
      return initialState;

    case types.SUBMIT_BINANCE_TRANSFER_REQUEST_INIT:
      return state.mergeIn(['transferRequest'], {
        isSubmitting: true,
        isSubmittingError: false,
        data: null,
        message: null
      });
    case types.SUBMIT_BINANCE_TRANSFER_REQUEST_SUCCESS:
      return state.mergeIn(['transferRequest'], {
        isSubmitting: false,
        isSubmittingError: false,
        message: null,
        data: normalizeSingleAssetTransfer(action.data)
      });
    case types.SUBMIT_BINANCE_TRANSFER_REQUEST_ERROR:
      return state.mergeIn(['transferRequest'], {
        isSubmitting: false,
        isSubmittingError: true,
        message: action.message
      });
    case types.SUBMIT_BINANCE_TRANSFER_REQUEST_RESET:
      return state.mergeIn(['transferRequest'], {
        isSubmitting: false,
        isSubmittingError: false,
        data: null,
        message: null
      });
    case types.ASSET_TRANSFER_CURRENCIES_INIT:
      return state.mergeIn(['assetTransferCurrencies'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.ASSET_TRANSFER_CURRENCIES_SUCCESS:
      return state.mergeIn(['assetTransferCurrencies'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeAssetTransferCurrencies(action.data)
      });
    case types.ASSET_TRANSFER_CURRENCIES_ERROR:
      return state.mergeIn(['assetTransferCurrencies'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.INITIATE_PAYMENT_GATEWAY_DEPOSIT_INIT:
      return state.mergeIn(['initiatePaymentGatewayDeposit'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.INITIATE_PAYMENT_GATEWAY_DEPOSIT_SUCCESS: {
      const { transactionId, data } = action;

      let newState = state.mergeIn(['initiatePaymentGatewayDeposit'], {
        isFetching: false,
        isFetchingError: false
      });

      newState = newState.mergeIn(['deposit', 'gateway', transactionId], {
        data: normalizeGatewayDeposit(data)
      });

      return newState;
    }
    case types.INITIATE_PAYMENT_GATEWAY_DEPOSIT_ERROR:
      return state.mergeIn(['initiatePaymentGatewayDeposit'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.FETCH_DEPOSIT_TRANSACTION_INFO_START:
      return state.mergeIn(['deposit', 'gateway', action.transactionId], {
        isPolling: true
      });
    case types.FETCH_DEPOSIT_TRANSACTION_INFO_SUCCESS: {
      const isPolling =
        [
          TRANSACTION_STATES.ACCEPTED,
          TRANSACTION_STATES.CANCELLED,
          TRANSACTION_STATES.REJECTED,
          TRANSACTION_STATES.WARNING
        ].indexOf(action.data.state) === -1;
      return state.mergeIn(['deposit', 'gateway', action.transactionId], {
        data: normalizeGatewayDeposit(action.data),
        isPolling
      });
    }
    case types.FETCH_DEPOSIT_TRANSACTION_INFO_STOP:
      return state.mergeIn(['deposit', 'gateway', action.transactionId], {
        isPolling: false
      });

    case types.GET_DUSTS_INIT:
      return state.mergeIn(['dusts'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.GET_DUSTS_SUCCESS:
      return state.mergeIn(['dusts'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data.dusts
      });
    case types.GET_DUSTS_ERROR:
      return state.mergeIn(['dusts'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.CONVERT_DUSTS_INIT:
      return state.mergeIn(['convertDust'], {
        isPosting: true,
        isPostingError: false
      });
    case types.CONVERT_DUSTS_SUCCESS: {
      const { currencies } = action.data || [];
      const newState = state.updateIn(['dusts', 'data'], data =>
        data.filter(dust => currencies.indexOf(dust.get('currency')) === -1)
      );
      return newState.mergeIn(['convertDust'], {
        isPosting: false,
        isPostingError: false,
        data: action.data
      });
    }
    case types.CONVERT_DUSTS_ERROR:
      return state.mergeIn(['convertDust'], {
        isPosting: false,
        isPostingError: true,
        message: action.message
      });

    case types.FETCH_CURRENCY_NETWORKS_INIT:
      return state.mergeIn(['currencyNetworks'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_CURRENCY_NETWORKS_SUCCESS:
      oldCurrencyNetworksData =
        state.getIn(['currencyNetworks', 'data']) || fromJS({});
      //update the networks (not override)
      return state.mergeIn(['currencyNetworks'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeCurrencyNetworks(action.data, oldCurrencyNetworksData)
      });
    case types.FETCH_CURRENCY_NETWORKS_ERROR:
      return state.mergeIn(['currencyNetworks'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.FETCH_LIVE_CURRENCY_NETWORKS:
      oldCurrencyNetworksData =
        state.getIn(['currencyNetworks', 'data']) || fromJS({});
      //update the networks (not override)
      return state.mergeIn(['currencyNetworks'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeCurrencyNetworks(action.data, oldCurrencyNetworksData)
      });

    case types.FETCH_PORTFOLIO_INIT:
      return state.mergeIn(['portfolio'], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_PORTFOLIO_SUCCESS:
      return state.mergeIn(['portfolio'], {
        isFetching: false,
        isFetchingError: false,
        isFetchedOnce: true,
        data: normalizePortfolio(action.data)
      });

    case types.FETCH_PORTFOLIO_ERROR:
      return state.mergeIn(['portfolio'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    default:
      return state;
  }
}
