export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong...Please try again';
export const TWOFA_DECLINE_MESSAGE = 'Please verify mobile OTP to proceed';
export const DEFAULT_SUCCESS_MESSAGE = 'Data processed successfully.';
export const RESET_PASSWORD_INITIATE_SUCCESS =
  'Check your email for the reset link';
export const CANCEL_PAYMENT_REASONS = [
  'I do not want to trade anymore',
  'Clicked on "Yes, I will pay" by mistake',
  'Not sure how to make the payment to the seller',
  'Problem with chosen payment method',
  'Other reasons'
];
export const CANCEL_P2P_BUY_ORDER_REASONS = [
  'I do not want to trade anymore',
  'I want to modify the price',
  'It is taking longer time for matching',
  'I placed an order by mistake',
  'Other reasons'
];
export const OTHER_REASONS = 'Other reasons';
export const UPLOAD_FILE_ERROR_MESSAGE =
  'The file you are trying to upload seems to be corrupted. Please try again or try with a different file.';
