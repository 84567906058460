import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeOTCOrderDetails,
  normalizeOrdersList
} from '../utils/normalizer/otc';
import {
  normalizeLiveOrderHistory,
  deleteFromOrder
} from '../utils/normalizer/orders';
import { MY_ORDER_MODE } from '../constants/Core';

const initialState = fromJS({
  orders: {}, //for order listing
  currentOrder: {} //for the order being currently placed
});

export default function(state = initialState, action) {
  let normalizedOrders;
  let updatedState;
  let oldOrderData;

  switch (action.type) {
    case types.OTC_FETCH_ORDERS_INIT:
      return state.mergeIn(['orders', action.orderMode], {
        isFetching: true,
        isFetchingError: false,
        hasMore: false
      });
    case types.OTC_FETCH_ORDERS_SUCCESS: {
      normalizedOrders = normalizeOrdersList(
        action.data.orders,
        action.orderMode
      );
      updatedState = {
        isFetching: false,
        isFetchingError: false,
        data: normalizedOrders,
        hasMore: action?.data?.orders?.length === action.pageSize
      };

      return state.mergeIn(['orders', action.orderMode], updatedState);
    }
    case types.OTC_FETCH_ORDERS_ERROR:
      return state.mergeIn(['orders', action.orderMode], {
        isFetching: false,
        isFetchingError: true,
        message: action.message,
        hasMore: false
      });

    case types.OTC_FETCH_ORDERS_LIVE: {
      const currentOrders = state.getIn(['orders', action.orderMode, 'data']);
      return state.mergeIn(['orders', action.orderMode], {
        data: normalizeLiveOrderHistory(
          action.orderMode,
          action.data,
          currentOrders
        )
      });
    }
    case types.OTC_FETCH_ORDER_DETAIL_INIT: {
      const orderDetail = {
        isFetchingOrderDetails: true,
        isFetchingOrderDetailsError: false,
        errorMessage: null,
        id: action.data.id
      };

      const orderIndex = state
        .getIn(['orders', action.orderMode, 'data'])
        ?.findIndex(order => order.get('id') === action.data.id);

      if (orderIndex > -1) {
        return state.mergeIn(
          ['orders', action.orderMode, 'data', orderIndex],
          orderDetail
        );
      } else {
        return state.updateIn(['orders', action.orderMode, 'data'], data => {
          return data.push(fromJS(orderDetail));
        });
      }
    }
    case types.OTC_FETCH_ORDER_DETAIL_SUCCESS: {
      const orderIndex = state
        .getIn(['orders', action.orderMode, 'data'])
        .findIndex(order => order.get('id') === action.data.id);

      const orderDetail = {
        isFetchingOrderDetails: false,
        isFetchingOrderDetailsError: false,
        errorMessage: null,
        id: action.data.id,
        ...normalizeOTCOrderDetails(action.json)
      };

      if (orderIndex > -1) {
        return state.mergeIn(
          ['orders', action.orderMode, 'data', orderIndex],
          orderDetail
        );
      } else {
        return state.updateIn(['orders', action.orderMode, 'data'], data => {
          return data.push(fromJS(orderDetail));
        });
      }
    }

    case types.OTC_FETCH_ORDER_DETAIL_ERROR: {
      const orderIndex = state
        .getIn(['orders', action.orderMode, 'data'])
        .findIndex(order => order.get('id') === action.data.id);

      const orderDetail = {
        isFetchingOrderDetails: false,
        isFetchingOrderDetailsError: true,
        errorMessage: action.message,
        id: action.data.id
      };

      if (orderIndex > -1) {
        return state.mergeIn(
          ['orders', action.orderMode, 'data', orderIndex],
          orderDetail
        );
      } else {
        return state.updateIn(['orders', action.orderMode, 'data'], data => {
          return data.push(fromJS(orderDetail));
        });
      }
    }

    case types.OTC_PLACE_ORDER_INIT:
      return state.mergeIn(['currentOrder'], {
        isPlacing: true,
        isPlacingError: false
      });
    case types.OTC_PLACE_ORDER_SUCCESS:
      return state.set(
        'currentOrder',
        fromJS({
          isPlacing: false,
          isPlacingError: false,
          ...normalizeOTCOrderDetails(action.data)
        })
      );
    case types.OTC_PLACE_ORDER_ERROR:
      return state.mergeIn(['currentOrder'], {
        isPlacing: false,
        isPlacingError: true,
        message: action.message
      });

    case types.OTC_CANCEL_ORDER_INIT:
      return state.mergeIn(['orders', MY_ORDER_MODE.OPEN], {
        isDeleting: true,
        isDeletingError: false
      });

    case types.OTC_CANCEL_ORDER_SUCCESS: {
      oldOrderData = state.getIn(['orders', MY_ORDER_MODE.OPEN, 'data']);
      const filteredOrders = deleteFromOrder(oldOrderData, action.data.id);
      return state.mergeIn(['orders', MY_ORDER_MODE.OPEN], {
        isDeleting: false,
        isDeletingError: false,
        data: filteredOrders
      });
    }
    case types.OTC_CANCEL_ORDER_ERROR:
      return state.mergeIn(['orders', MY_ORDER_MODE.OPEN], {
        isDeleting: false,
        isDeletingError: true,
        errorMessage: action.message
      });

    default:
      return state;
  }
}
