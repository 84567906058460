import values from 'lodash/values';

import { DEFAULT_KYC_FORM_COUNTRY } from '../../constants/Verification';

export const normalizeKYCRenderFormat = data => data;

export const normalizeCountriesList = data => {
  const normalizedCountryList = {
    countries: [],
    defaultCountry: data.defaultCountry || DEFAULT_KYC_FORM_COUNTRY
  };
  data &&
    data.countries &&
    data.countries.forEach(country => {
      if (country.alpha2Code) {
        normalizedCountryList.countries.push({
          value: country.alpha2Code,
          label: country.name,
          isKycAllowed: !!country.isKycAllowed,
          supportedKycTypes: normalizeSupportedKycTypes(
            country.supportedKycTypes
          )
        });
      }
    });
  return normalizedCountryList;
};

export const normalizeSupportedKycTypes = supportedKycTypes => {
  return supportedKycTypes.map(
    ({ isDefault = false, name, subTypes, type }) => {
      return {
        isDefault,
        label: name,
        subTypes: subTypes.map(subType => ({
          isDefault: !!subType.isDefault,
          label: subType.name,
          value: subType.type
        })),
        value: type
      };
    }
  );
};

export const normalizeCallingCodes = data => {
  const callingCodes =
    data && data.countries
      ? values(data.countries).map(
          country => country.alpha2Code && country.alpha2Code.toLowerCase()
        )
      : [];
  return { callingCodes, defaultCountry: data && data.defaultCountry };
};
