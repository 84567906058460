import styled from 'styled-components';

import Flex from '../../common/Flex';
import COLORS from '../../common/helpers/colors';

export const Header = styled(Flex)`
  border-bottom: 1px solid ${COLORS.divider};
  position: relative;
`;

export const InfoIcon = styled.i`
  color: ${COLORS.text.l2};
  font-size: 16px;
  margin-left: 6px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px 24px;
  width: 100%;
  background-color: ${COLORS.background.l0};
`;
