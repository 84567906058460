import { KYC_VERIFICATION_STATES } from './Core';

export const VERIFICATION_STAGE_NAMES = {
  kyc: 'Welcome',
  security: 'Security',
  email: 'Email'
};

export const VERIFICATION_STAGES = {
  KYC: 'kyc',
  SECURITY: 'security',
  EMAIL: 'email',
  DONE: 'done'
};

export const KYC_FORM_VISIBILITY_STATUSES = [
  KYC_VERIFICATION_STATES.UNVERIFIED,
  KYC_VERIFICATION_STATES.VERIFIED,
  KYC_VERIFICATION_STATES.REJECTED
];

export const DEFAULT_KYC_FORM_COUNTRY = 'IN';
