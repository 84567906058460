import Big from 'big.js';
import { format } from 'date-fns';
import {
  EMPTY_VALUES,
  ORDER_TYPES,
  KIND_TO_SIDE_MAPPER
} from '../../constants/Core';

import {
  EXCHANGE_ORDER_STATE,
  ORDER_STATUS
} from '../../constants/StatusCodes';

import { getQuotePrecision, getBasePrecision } from './common';
import { toFixed } from '../core';
import { getJSONObjectFromLocalStorage } from '../localStorageHelpers';

import { getBaseAndQuoteCurrencyFromMarket } from './exchange';

export const getOrderStatusFromState = (executed, state) => {
  if (!executed.eq(0) && state === EXCHANGE_ORDER_STATE.CANCEL) {
    return ORDER_STATUS.partiallyDone;
  }
  return ORDER_STATUS[state];
};

export const normalizeOTCOrderDetails = ({
  id,
  at,
  avgPrice,
  kind,
  market,
  ord_type,
  originVolume,
  price,
  state,
  volume,
  trades
}) => {
  const time = new Date(at * 1000);
  const { base, quote } = getBaseAndQuoteCurrencyFromMarket(market);
  const quotePrecision = getQuotePrecision(base, quote);
  const basePrecision = getBasePrecision(base);

  const bigVolume = new Big(volume);
  const bigOriginVolume = new Big(originVolume);
  const bigPrice = new Big(price);

  const bigAveragePrice = new Big(avgPrice);
  let bigExecutedVolume = bigOriginVolume.minus(bigVolume);

  const bigTotal = bigPrice.mul(
    state === EXCHANGE_ORDER_STATE.WAIT ? bigOriginVolume : bigExecutedVolume
  );

  bigExecutedVolume = bigExecutedVolume.eq(0) ? new Big(0) : bigExecutedVolume;

  const totalExecuted = bigAveragePrice.mul(bigExecutedVolume);

  const orderExecutionPercentage = bigExecutedVolume
    ?.div(bigOriginVolume)
    ?.mul(100)
    ?.toString();

  const status = getOrderStatusFromState(bigExecutedVolume, state);
  let orderTrades = [];
  let fees = {};

  if (trades) {
    trades.forEach(trade => {
      const createdAt = new Date(trade.created_at || trade.at);
      const fee = new Big(trade.fee);
      const feeCurrency = trade.fee_currency || trade.feeCurrency;
      const funds = new Big(trade.funds);
      const price = new Big(trade.price);
      const volume = new Big(trade.volume);

      fees[feeCurrency] = fees[feeCurrency] ? fees[feeCurrency].plus(fee) : fee;
      orderTrades.push({
        id: trade.id,
        createdAt,
        fee,
        feeCurrency,
        funds,
        price,
        volume
      });
    });
  }

  return {
    id,
    price: toFixed(bigPrice, quotePrecision),
    volume: toFixed(bigOriginVolume, basePrecision),
    time: format(time, 'dd MMM, HH:mm'),
    type: KIND_TO_SIDE_MAPPER[kind],
    market,
    total: toFixed(bigTotal, quotePrecision),
    executed: toFixed(bigExecutedVolume, basePrecision),
    totalExecuted: toFixed(totalExecuted, quotePrecision),
    orderExecutionPercentage,
    status,
    base,
    quote,
    ordType: ord_type || ORDER_TYPES.LIMIT,
    averagePrice: toFixed(bigAveragePrice, quotePrecision),
    fees: Object.entries(fees),
    trades: orderTrades
  };
};

export const normalizeOrdersList = orders => {
  return orders && orders.map(order => normalizeOTCOrderDetails(order));
};

export const getBaseAndQuoteCurrencyFromOTCMarket = marketType => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.otcMarkets) {
    const selectedMarket = config.otcMarkets[marketType];
    return (
      selectedMarket && {
        quote: selectedMarket.quoteMarket,
        base: selectedMarket.baseMarket
      }
    );
  }
  return null;
};

export const getDefaultOTCMarket = () => {
  const config = getJSONObjectFromLocalStorage('config');
  return (
    config?.defaultOTCMarket && config?.otcMarkets?.[config?.defaultOTCMarket]
  );
};

export const getOTCFees = market => {
  const config = getJSONObjectFromLocalStorage('config');
  const multiplierMaker = config?.otcMarkets?.[market]?.['makerFee'];
  const multiplierTaker = config?.otcMarkets?.[market]?.['takerFee'];
  return {
    makerFee:
      EMPTY_VALUES.indexOf(multiplierMaker) === -1 ? multiplierMaker * 100 : 0,
    takerFee:
      EMPTY_VALUES.indexOf(multiplierTaker) === -1 ? multiplierTaker * 100 : 0
  };
};
