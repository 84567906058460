import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configureStore from './store';
import './style/index.scss';
import GlobalStyles from './index.style';
// eslint-disable-next-line import/no-named-as-default-member
import ApiConstants from './constants/ApiConstants';
import {
  getJSONObjectFromLocalStorage,
  setJSONObjectToLocalStorage
} from './utils/localStorageHelpers';
import './i18n';
import ErrorPage from './components/ErrorBoundary.js/ErrorPage';
import {
  checkMutedExceptions,
  sentryDenyUrls,
  sentryIgnoreErrors
} from './utils/sentryHelpers';
import AppContainer from './containers/AppContainer';

const { SENTRY_CONFIG, GA_TRACKING_KEY } = ApiConstants;
const appState = getJSONObjectFromLocalStorage('local');
const persistedState = appState || undefined;
const store = configureStore(persistedState);
const history = createBrowserHistory();

store.subscribe(() => {
  const { Settings } = store.getState();
  if (Settings) {
    setJSONObjectToLocalStorage('local', {
      Settings
    });
  }
});

Sentry.init({
  dsn: SENTRY_CONFIG,
  beforeSend: (event, hint) => {
    // In order to avoid seeing a lot of some errors on sentry dashboard because of external reasons
    // check if the exceptions being logged are muted
    if (!checkMutedExceptions(event, hint)) {
      //if not muted, proceed with logging

      // also remove the token in url if present
      if (event?.url && event?.url?.indexOf?.('token') >= 0) {
        event.url = 'token_masked_url'; // update this to remove the value of token as xxxxxx - only mask that.
      }

      return event;
    } else {
      //if muted, don't proceed
      return null;
    }
  },
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  tracesSampleRate: 0.05,
  denyUrls: sentryDenyUrls(),
  ignoreErrors: sentryIgnoreErrors()
});

const tagManagerArgs = {
  gtmId: GA_TRACKING_KEY
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/" history={history}>
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <AppContainer history={history} />
        <GlobalStyles />
      </Sentry.ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);
