import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeP2POrderDetails,
  normalizeOrdersList,
  normalizeP2POrderBook,
  normalizeP2PTradeHistory
} from '../utils/normalizer/p2p';
import { TRADE_STATES, ORDER_STATES, ORDER_VIEW_TYPE } from '../constants/Core';

const initialState = fromJS({
  price: {
    isFetching: false,
    isFetchingError: false
  },
  orders: {},
  book: {},
  bookByUsername: {},
  tradeHistory: {},
  currentOrder: {},
  articles: {}
});

export default function(state = initialState, action) {
  let tradeIndex;
  let normalizedOrders;
  let updatedState;

  switch (action.type) {
    case types.P2P_FETCH_PRICE_INIT:
      return state.mergeIn(['price'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.P2P_FETCH_PRICE_SUCCESS:
      return state.mergeIn(['price'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.P2P_FETCH_PRICE_ERROR:
      return state.mergeIn(['price'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.P2P_FETCH_ORDERS_INIT:
      return state.mergeIn(['orders', action.orderViewType], {
        isFetching: true,
        isFetchingError: false,
        hasMore: false
      });
    case types.P2P_FETCH_ORDERS_SUCCESS: {
      normalizedOrders = normalizeOrdersList(
        action.data.orders,
        action.orderViewType
      );
      updatedState = {
        isFetching: false,
        isFetchingError: false,
        data: normalizedOrders,
        hasMore:
          action.data &&
          action.data.orders &&
          action.data.orders.length === action.pageSize
      };

      if (action.orderViewType === ORDER_VIEW_TYPE.ACTIVE) {
        updatedState.areActionsPending =
          normalizedOrders.filter(order => order.pendingActions > 0).length > 0;
      }

      return state.mergeIn(['orders', action.orderViewType], updatedState);
    }
    case types.P2P_FETCH_ORDERS_ERROR:
      return state.mergeIn(['orders', action.orderViewType], {
        isFetching: false,
        isFetchingError: true,
        message: action.message,
        hasMore: false
      });

    case types.P2P_FETCH_ORDER_DETAIL_INIT:
      // orderIndex = state.getIn(['orders','data']).findIndex(order => order.get('id') === order.id);
      // return state.mergeIn(['orders','data', orderIndex],{
      return state.mergeIn(['currentOrder'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.P2P_FETCH_ORDER_DETAIL_SUCCESS:
      // orderIndex = state.getIn(['orders','data']).findIndex(order => order.get('id') === order.id);
      // return state.mergeIn(['orders','data', orderIndex],{
      return state.mergeIn(['currentOrder'], {
        isFetching: false,
        isFetchingError: false,
        ...normalizeP2POrderDetails(action.data)
      });
    case types.P2P_FETCH_ORDER_DETAIL_ERROR:
      // orderIndex = state.getIn(['orders','data']).findIndex(order => order.get('id') === order.id);
      // return state.mergeIn(['orders','data', orderIndex],{
      return state.mergeIn(['currentOrder'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.P2P_PLACE_ORDER_INIT:
      return state.mergeIn(['currentOrder'], {
        isPlacing: true,
        isPlacingError: false
      });
    case types.P2P_PLACE_ORDER_SUCCESS:
      return state.set(
        'currentOrder',
        fromJS({
          isPlacing: false,
          isPlacingError: false,
          ...normalizeP2POrderDetails(action.data),
          isPolling: false
        })
      );
    case types.P2P_PLACE_ORDER_ERROR:
      return state.mergeIn(['currentOrder'], {
        isPlacing: false,
        isPlacingError: true,
        message: action.message
      });
    case types.P2P_UPDATE_ORDER_STATUS: {
      return state.mergeIn(['currentOrder'], {
        ...normalizeP2POrderDetails(action.data),
        timeStamp: Date.now()
      });
    }
    case types.P2P_UPDATE_ORDER_STATUS_START:
      return state.mergeIn(['currentOrder'], {
        isPolling: true
      });
    case types.P2P_UPDATE_ORDER_STATUS_STOP:
      return state.mergeIn(['currentOrder'], {
        isPolling: false
      });
    case types.P2P_FETCH_ORDER_BOOK_INIT:
      return state.mergeIn(['book'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.P2P_FETCH_ORDER_BOOK_SUCCESS:
      return state.mergeIn(['book'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeP2POrderBook(action.data, action.market)
      });
    case types.P2P_FETCH_ORDER_BOOK_ERROR:
      return state.mergeIn(['book'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.P2P_UPDATE_ORDER_BOOK: {
      return state.mergeIn(['book'], {
        data: normalizeP2POrderBook(action.data, action.market, action.limit)
      });
    }
    case types.P2P_FETCH_ORDER_BOOK_BY_USERNAME_INIT:
      return state.mergeIn(['bookByUsername'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.P2P_FETCH_ORDER_BOOK_BY_USERNAME_SUCCESS:
      return state.mergeIn(['bookByUsername'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeP2POrderBook(action.data, action.market)
      });
    case types.P2P_FETCH_ORDER_BOOK_BY_USERNAME_ERROR:
      return state.mergeIn(['bookByUsername'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.P2P_UPDATE_ORDER_BOOK_BY_USERNAME: {
      return state.mergeIn(['bookByUsername'], {
        data: normalizeP2POrderBook(action.data, action.market, action.limit)
      });
    }
    case types.P2P_FETCH_TRADE_HISTORY_INIT:
      return state.mergeIn(['tradeHistory'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.P2P_FETCH_TRADE_HISTORY_SUCCESS: {
      return state.mergeIn(['tradeHistory'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeP2PTradeHistory(action.data, action.market, action.limit)
      });
    }
    case types.P2P_FETCH_TRADE_HISTORY_ERROR:
      return state.mergeIn(['tradeHistory'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.P2P_UPDATE_TRADE_HISTORY: {
      return state.mergeIn(['tradeHistory'], {
        data: normalizeP2PTradeHistory(action.data, action.market, action.limit)
      });
    }
    case types.P2P_CANCEL_ORDER_SUCCESS: {
      if (action.data.success) {
        return state.mergeIn(['currentOrder'], {
          state: ORDER_STATES.CANCEL
        });
      }
      return state;
    }
    case types.P2P_BUYER_PAYMENT_CONFIRM_INIT:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isConfirming: true,
        isConfirmedError: false
      });
    case types.P2P_BUYER_PAYMENT_CONFIRM_SUCCESS:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isConfirming: false,
        bidFundTransferred: action.data.success,
        message: action.data.message,
        isConfirmedError: false
      });
    case types.P2P_BUYER_PAYMENT_CONFIRM_ERROR:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isConfirming: false,
        isConfirmedError: true,
        ...action.message
      });
    case types.P2P_BUYER_PAYMENT_ACCEPTANCE_INIT:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isBuyerAccepting: true,
        isBuyerAcceptingError: false
      });
    case types.P2P_BUYER_PAYMENT_ACCEPTANCE_SUCCESS:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isBuyerAccepting: false,
        buyerAccepted: true,
        isBuyerAcceptingError: false
      });
    case types.P2P_BUYER_PAYMENT_ACCEPTANCE_ERROR:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isBuyerAccepting: false,
        isBuyerAcceptingError: true,
        ...action.message
      });

    case types.P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_INIT:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isConfirming: true,
        isConfirmedError: false
      });
    case types.P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_SUCCESS:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isConfirming: false,
        isConfirmedError: false,
        askFundReceived: action.data.success,
        message: action.data.message,
        state: TRADE_STATES.DONE
      });
    case types.P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_ERROR:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        isConfirming: false,
        isConfirmedError: true,
        ...action.message
      });
    case types.P2P_RAISE_DISPUTE_INIT:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', 'dispute', tradeIndex], {
        isRaisingDispute: true,
        isRaisingDisputeError: false
      });
    case types.P2P_RAISE_DISPUTE_SUCCESS:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', 'dispute', tradeIndex], {
        isRaisingDispute: false,
        isRaisingDisputeError: true,
        ...action.data
      });
    case types.P2P_RAISE_DISPUTE_ERROR:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', 'dispute', tradeIndex], {
        isRaisingDispute: false,
        isRaisingDisputeError: true,
        ...action.message
      });
    case types.P2P_FETCH_P2P_HELP_ARTICLES_INIT:
      return state.mergeIn(['articles'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.P2P_FETCH_P2P_HELP_ARTICLES_SUCCESS:
      return state.mergeIn(['articles'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.P2P_FETCH_P2P_HELP_ARTICLES_ERROR:
      return state.mergeIn(['articles'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    case types.P2P_SUBMIT_PAYMENT_PROOF_INIT:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        uploading: true,
        uploadingError: false
      });
    case types.P2P_SUBMIT_PAYMENT_PROOF_SUCCESS:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        uploading: false,
        proofUploaded: action.data.success,
        message: action.data.message,
        uploadingError: false
      });
    case types.P2P_SUBMIT_PAYMENT_PROOF_ERROR:
      tradeIndex = state
        .getIn(['currentOrder', 'trades'])
        .findIndex(trade => trade.get('uid') === action.uid);
      return state.mergeIn(['currentOrder', 'trades', tradeIndex], {
        uploading: false,
        uploadingError: true,
        ...action.message
      });

    default:
      return state;
  }
}
