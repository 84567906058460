import COLORS from '../components/common/helpers/colors';

export const ORDER_STATUS = {
  idle: 'Idle',
  wait: 'Pending',
  done: 'Executed',
  partiallyDone: 'Partially Completed',
  cancel: 'Cancelled'
};

export const STATUS_INFO_TYPES_COLOR_CODES = {
  General: COLORS.primary,
  Positive: COLORS.green,
  Negative: COLORS.red,
  Warning: COLORS.yellow
};

export const DEPOSIT_BONUS_STATUS_MAPPER = {
  wait: 'Active',
  processing: 'Processing',
  cancel: 'Inactive',
  done: 'Matured'
};

export const EXCHANGE_ORDER_STATE = {
  IDLE: 'idle',
  WAIT: 'wait',
  CANCEL: 'cancel',
  DONE: 'done'
};
