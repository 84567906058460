import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import { GIFT_MODE } from '../constants/Core';
import {
  normalizeGiftList,
  normalizeSingleGift
} from '../utils/normalizer/gifts';

const initialState = fromJS({
  count: {
    opened: 0,
    unopened: 0
  },
  received: {
    isFetching: false,
    isFetchingError: false,
    data: []
  },
  sent: {
    isFetching: false,
    isFetchingError: false,
    data: []
  },
  details: {
    isFetching: false,
    isFetchingError: false,
    data: {}
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_RECEIVED_GIFTS_INIT:
      return state.mergeIn(['received'], {
        isFetching: true,
        isFetchingError: false,
        data: null,
        message: null
      });

    case types.FETCH_RECEIVED_GIFTS_SUCCESS: {
      const receivedGiftsList = normalizeGiftList(
        action.data,
        GIFT_MODE.RECEIVED
      );

      let unopenedCount = 0;
      let openedCount = 0;
      receivedGiftsList &&
        receivedGiftsList.forEach(gift => {
          if (!gift?._isOpened) {
            unopenedCount++;
          } else {
            openedCount++;
          }
        });

      return state
        .mergeIn(['received'], {
          isFetching: false,
          isFetchingError: false,
          data: receivedGiftsList
        })
        .mergeIn(['count'], {
          opened: openedCount,
          unopened: unopenedCount
        });
    }
    case types.FETCH_RECEIVED_GIFTS_ERROR:
      return state.mergeIn(['received'], {
        isFetching: false,
        isFetchingError: true,
        message: action?.data
      });

    case types.FETCH_SENT_GIFTS_INIT:
      return state.mergeIn(['sent'], {
        isFetching: true,
        isFetchingError: false,
        data: null,
        message: null
      });

    case types.FETCH_SENT_GIFTS_SUCCESS:
      return state.mergeIn(['sent'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeGiftList(action.data, GIFT_MODE.SENT)
      });

    case types.FETCH_SENT_GIFTS_ERROR:
      return state.mergeIn(['sent'], {
        isFetching: false,
        isFetchingError: true,
        message: action?.data
      });

    case types.FETCH_GIFT_DETAILS_INIT:
      return state.mergeIn(['details'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });

    case types.FETCH_GIFT_DETAILS_SUCCESS:
      return state.mergeIn(['details'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeSingleGift(action.data)
      });

    case types.FETCH_GIFT_DETAILS_ERROR:
      return state.mergeIn(['details'], {
        isFetching: false,
        isFetchingError: true
      });

    case types.FETCH_ACCEPT_REJECT_GIFTS_SUCCESS: {
      const newGiftDetails = normalizeSingleGift(action.data);

      // When the user accepts/rejects any gift:
      // 1. update the gift details popup
      // 2. update the new gift details in received-gifts list (to update opened/unopened sections)
      // 3. update the count and remove the notification dot from navbar

      return state
        .mergeIn(['details'], {
          isFetching: false,
          isFetchingError: false,
          data: newGiftDetails
        })
        .updateIn(['received', 'data'], data => {
          const newData = data.map(gift => {
            if (gift.get('id') === newGiftDetails?.id) {
              return fromJS({
                ...newGiftDetails
              });
            } else {
              return gift;
            }
          });

          return newData;
        })
        .updateIn(['count'], data => {
          return fromJS({
            opened: (data?.get?.('opened') || 0) + 1,
            unopened: (data?.get?.('unopened') || 0) - 1
          });
        });
    }

    case types.FETCH_ACCEPT_REJECT_GIFTS_RESET:
      return state.mergeIn(['details'], {
        isFetching: false,
        isFetchingError: false,
        data: {}
      });

    case types.FETCH_ACCEPT_REJECT_GIFTS_ERROR:
      return state.mergeIn(['details'], {
        isFetching: false,
        isFetchingError: true
      });
    default:
      return state;
  }
}
