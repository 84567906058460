import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import { normalizeGatewayDeposit } from '../utils/normalizer/transfers';

const initialState = fromJS({
  modes: {
    isFetching: false,
    isFetchingError: false
  },
  fees: {
    isFetching: false,
    isFetchingError: false
  },
  paymentOptions: {
    isFetching: false,
    isFetchingError: false
  },
  transaction: {
    isSaving: false,
    isSavingError: false
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DEPOSITS_INIT:
      return state.mergeIn(['depositModes'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    case types.FETCH_DEPOSITS_SUCCESS:
      return state.mergeIn(['depositModes'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_DEPOSITS_ERROR:
      return state.mergeIn(['depositModes'], {
        isFetching: false,
        isFetchingError: true,
        data: action.data
      });
    case types.FETCH_DEPOSITS_PAYMENT_OPTIONS_INIT:
      return state.mergeIn(['paymentOptions'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    case types.FETCH_DEPOSITS_PAYMENT_OPTIONS_SUCCESS:
      return state.mergeIn(['paymentOptions'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_DEPOSITS_PAYMENT_OPTIONS_ERROR:
      return state.mergeIn(['paymentOptions'], {
        isFetching: false,
        isFetchingError: true,
        data: null,
        error: action.data
      });
    case types.FETCH_DEPOSITS_FEES_INIT:
      return state.mergeIn(['fees'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    case types.FETCH_DEPOSITS_FEES_SUCCESS:
      return state.mergeIn(['fees'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_DEPOSITS_FEES_ERROR:
      return state.mergeIn(['fees'], {
        isFetching: false,
        isFetchingError: true,
        data: null,
        error: action.data
      });
    case types.SUBMIT_DEPOSIT_INIT:
      return state.mergeIn(['transaction'], {
        isSaving: true,
        isSavingError: false
      });

    case types.SUBMIT_DEPOSIT_SUCCESS:
      return state.mergeIn(['transaction'], {
        isSaving: false,
        isSavingError: false,
        data: {
          ...action.data,
          deposit: normalizeGatewayDeposit(action.data?.deposit)
        },
        error: null
      });

    case types.SUBMIT_DEPOSIT_ERROR:
      return state.mergeIn(['transaction'], {
        isSaving: false,
        isSavingError: true,
        error: action?.message,
        data: null
      });

    case types.RESET_DEPOSIT_DATA:
      return state.mergeIn(['transaction'], {
        isSaving: false,
        isSavingError: false,
        error: null,
        data: null
      });
    default:
      return state;
  }
}
