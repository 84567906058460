import { format } from 'date-fns';
import Big from 'big.js';
import { fromJS } from 'immutable';
import { toFixed } from '../core';
import {
  LIMIT_API_RESPONSE,
  EMPTY_VALUES,
  ORDER_TYPES,
  KIND_TO_SIDE_MAPPER,
  MY_ORDER_MODE
} from '../../constants/Core';
import {
  ORDER_STATUS,
  EXCHANGE_ORDER_STATE
} from '../../constants/StatusCodes';

import { getBaseAndQuoteCurrencyFromMarket } from './exchange';
import { getQuotePrecision, getBasePrecision } from './common';

export const normalizeOrderBook = (orders, selectedMarket) => {
  const book = {};
  const { base, quote } = getBaseAndQuoteCurrencyFromMarket(selectedMarket);
  const quotePrecision = getQuotePrecision(base, quote);
  const currentDate = Date.now();
  book.asks = orders.asks.slice(0, LIMIT_API_RESPONSE).map((order, index) => ({
    id: currentDate + index,
    price: toFixed(order[0], quotePrecision),
    volume: order[1]
  }));

  book.bids = orders.bids.slice(0, LIMIT_API_RESPONSE).map((order, index) => ({
    id: currentDate + index,
    price: toFixed(order[0], quotePrecision),
    volume: order[1]
  }));

  return book;
};

export const getOrderStatusFromState = (executed, state) => {
  if (!executed.eq(0) && state === EXCHANGE_ORDER_STATE.CANCEL) {
    return ORDER_STATUS.partiallyDone;
  }
  return ORDER_STATUS[state];
};

export const normalizeSingleOrderHistory = ({
  id,
  at,
  avg_price,
  kind,
  market,
  ord_type,
  origin_volume,
  price,
  state,
  volume,
  stop_price,
  stop_limit_triggered_at,
  trades
}) => {
  const time = new Date(at * 1000);
  const { base, quote } = getBaseAndQuoteCurrencyFromMarket(market);
  const quotePrecision = getQuotePrecision(base, quote);
  const basePrecision = getBasePrecision(base);

  const bigVolume = new Big(volume);
  const bigOriginVolume = new Big(origin_volume);
  const bigPrice = new Big(price);
  const bigStopPrice =
    EMPTY_VALUES.indexOf(stop_price) === -1 ? new Big(stop_price) : undefined;
  const stopLimitTriggeredAt =
    EMPTY_VALUES.indexOf(stop_limit_triggered_at) === -1
      ? new Date(stop_limit_triggered_at)
      : undefined;

  const bigAveragePrice = new Big(avg_price);
  let bigExecutedVolume = bigOriginVolume.minus(bigVolume);

  const bigTotal = bigPrice.mul(
    state === EXCHANGE_ORDER_STATE.WAIT ? bigOriginVolume : bigExecutedVolume
  );

  bigExecutedVolume = bigExecutedVolume.eq(0) ? new Big(0) : bigExecutedVolume;

  const totalExecuted = bigAveragePrice.mul(bigExecutedVolume);

  const orderExecutionPercentage = bigExecutedVolume
    .div(bigOriginVolume)
    .mul(100);

  const status = getOrderStatusFromState(bigExecutedVolume, state);
  let orderTrades = [];
  let fees = {};
  let tds = {};

  if (trades) {
    trades.forEach(trade => {
      const createdAt = new Date(trade.created_at);
      const fee = new Big(trade.fee);
      const feeCurrency = trade.fee_currency;
      const funds = new Big(trade.funds);
      const price = new Big(trade.price);
      const volume = new Big(trade.volume);

      const trade_tds = new Big(trade.tds_amount || 0);
      const trade_tdsCurrency = trade.tds_currency;

      fees[feeCurrency] = fees[feeCurrency] ? fees[feeCurrency].plus(fee) : fee;
      tds[trade_tdsCurrency] = tds[trade_tdsCurrency]
        ? tds[trade_tdsCurrency].plus(trade_tds)
        : trade_tds;

      orderTrades.push({
        id: trade.id,
        createdAt,
        fee,
        feeCurrency,
        tds,
        trade_tdsCurrency,
        funds,
        price,
        volume
      });
    });
  }

  return {
    id,
    price: toFixed(bigPrice, quotePrecision),
    volume: toFixed(bigOriginVolume, basePrecision),
    remainingVolume: toFixed(bigVolume, basePrecision),
    time: format(time, 'dd MMM, HH:mm'),
    type: KIND_TO_SIDE_MAPPER[kind],
    market,
    total: toFixed(bigTotal, quotePrecision),
    executed: toFixed(bigExecutedVolume, basePrecision),
    totalExecuted: toFixed(totalExecuted, quotePrecision),
    orderExecutionPercentage,
    status,
    base,
    quote,
    stopPrice: bigStopPrice ? toFixed(bigStopPrice, quotePrecision) : undefined,
    ordType: ord_type || ORDER_TYPES.LIMIT,
    stopLimitTriggeredAt,
    averagePrice: toFixed(bigAveragePrice, quotePrecision),
    fees: Object.entries(fees),
    tds: Object.entries(tds),
    trades: orderTrades
  };
};

export const normalizeOrderHistory = orders =>
  orders.map(order => normalizeSingleOrderHistory(order));

export const normalizeLiveOrderHistory = (orderMode, newOrder, oldOrders) => {
  if (orderMode === MY_ORDER_MODE.OPEN) {
    if (
      [EXCHANGE_ORDER_STATE.CANCEL, EXCHANGE_ORDER_STATE.DONE].indexOf(
        newOrder.state
      ) > -1
    ) {
      return oldOrders.filter(order => order.get('id') !== newOrder.id);
    } else {
      const orderIndex = oldOrders.findIndex(
        order => order.get('id') === newOrder.id
      );
      const newOrderData = fromJS(normalizeSingleOrderHistory(newOrder));
      if (orderIndex > -1) {
        return oldOrders.set(orderIndex, newOrderData);
      }
      return oldOrders.unshift(newOrderData);
    }
  } else {
    if (
      [EXCHANGE_ORDER_STATE.CANCEL, EXCHANGE_ORDER_STATE.DONE].indexOf(
        newOrder.state
      ) > -1
    ) {
      const orderIndex = oldOrders.findIndex(
        order => order.get('id') === newOrder.id
      );
      const newOrderData = fromJS(normalizeSingleOrderHistory(newOrder));
      if (orderIndex > -1) {
        return oldOrders.set(orderIndex, newOrderData);
      }
      return oldOrders.unshift(newOrderData);
    }
    return oldOrders;
  }
};

export const deleteFromOrder = (orders, id) =>
  orders.filter(order => order.get('id') !== id);
