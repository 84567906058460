import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeKYCRenderFormat,
  normalizeCountriesList,
  normalizeCallingCodes
} from '../utils/normalizer/onboarding';

const initialState = fromJS({
  KYCRenderFormat: {
    isFetching: false,
    isFetchingError: false
  },
  countryData: {
    isFetching: false,
    isFetchingError: false,
    countries: []
  },
  KYCNativeStatus: {
    isFetching: false,
    isFetchingError: false
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.RESET_ONBOARDING_STATE: {
      return initialState;
    }
    case types.FETCH_KYC_FORMAT_INIT: {
      return state.mergeIn(['KYCRenderFormat'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    }
    case types.FETCH_KYC_FORMAT_SUCCESS: {
      return state.mergeIn(['KYCRenderFormat'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeKYCRenderFormat(action.data),
        countryCode: action.countryCode,
        kycType: action.kycType,
        kycSubType: action.kycSubType
      });
    }
    case types.FETCH_KYC_FORMAT_ERROR: {
      return state.mergeIn(['KYCRenderFormat'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    }
    case types.FETCH_KYC_NATIVE_STATUS_INIT: {
      return state.mergeIn(['KYCNativeStatus'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    }
    case types.FETCH_KYC_NATIVE_STATUS_SUCCESS: {
      return state.mergeIn(['KYCNativeStatus'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    }
    case types.FETCH_KYC_NATIVE_STATUS_ERROR: {
      return state.mergeIn(['KYCNativeStatus'], {
        isFetching: false,
        isFetchingError: true,
        error: action.message
      });
    }
    case types.FETCH_COUNTRIES_LIST_INIT: {
      return state.mergeIn(['countryData'], {
        isFetching: true,
        isFetchingError: false
      });
    }
    case types.FETCH_COUNTRIES_LIST_SUCCESS: {
      return state.mergeIn(['countryData'], {
        isFetching: false,
        isFetchingError: false,
        ...normalizeCountriesList(action.data)
      });
    }
    case types.FETCH_COUNTRIES_LIST_ERROR: {
      return state.mergeIn(['countryData'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    }
    case types.FETCH_CALLING_CODES_INIT: {
      return state.mergeIn(['callingCodes'], {
        isFetching: true,
        isFetchingError: false
      });
    }
    case types.FETCH_CALLING_CODES_SUCCESS: {
      return state.mergeIn(['callingCodes'], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeCallingCodes(action.data)
      });
    }
    case types.FETCH_CALLING_CODES_ERROR: {
      return state.mergeIn(['callingCodes'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    }
    default:
      return state;
  }
}
