import Big from 'big.js';
import fetch from 'isomorphic-fetch';
import { isAfter, isBefore, isDate } from 'date-fns';
import queryString from 'query-string';
import UAParser from 'ua-parser-js';

import {
  IOS_APP_DOWNLOAD_LINK,
  PLATFORM,
  ANDROID_APP_DOWNLOAD_LINK_HOME,
  INR_CURRENCY_DEFAULT_PARAMS,
  CRYPTO_CURRENCY_DEFAULT_PARAMS,
  FIAT_CURRENCY_DEFAULT_PARAMS,
  MOBILE_NAVBAR_HEIGHT,
  MOBILE_BOTTOM_TABS_HEIGHT,
  IS_MONEYCONTROL_DEACTIVATED
} from '../constants/Core';

import { getFromLocalStorage } from './localStorageHelpers';

import ApiConstants from '../constants/ApiConstants';

const {
  MOBILE_PAGE_LINK,
  MOBILE_APP_APN,
  MOBILE_APP_IBI,
  MOBILE_APP_ISI
} = ApiConstants;

export const getLocalAccessKey = () => getFromLocalStorage('accessKey');

export const getLocalSecretKey = () => getFromLocalStorage('secretKey');

export const getLocalFingerPrint = () => getFromLocalStorage('fingerPrint');

export const getLocalDeviceId = () => getFromLocalStorage('deviceId');

export const toFixed = (number, decimal) => {
  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`);
  const roundedNumber = number.toString().match(regex);
  if (number.e === undefined) {
    if (roundedNumber && roundedNumber.length > 0) {
      return new Big(roundedNumber[0]).toFixed(decimal);
    }
    return number;
  }
  return number.toFixed(decimal);
};

export const toRound = (number, decimal) => {
  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`);
  const roundedNumber = number.toString().match(regex);
  if (number.e === undefined) {
    if (roundedNumber && roundedNumber.length > 0) {
      return new Big(roundedNumber[0]).round(decimal).toFixed();
    }
    return number;
  }
  return number.round(decimal).toFixed();
};

export const isNumericAndNonZero = num => {
  const castedNumber = parseFloat(num);
  return (
    isNaN(castedNumber) || (!isNaN(castedNumber) && isFinite(num) && num > 0)
  );
};

export const toValidNumberOrZero = num => {
  // returns a valid number or ZERO
  // helpful where string numbers are being passed, then it type-casts those to numbers
  // helpful in avoiding NaN results in numeric calculations
  const isValidNumber = isNumeric(+num);
  return isValidNumber ? +num : 0;
};

export const allowOnlyAlphaNumeric = e => {
  const k = e.charCode;
  if (
    (k > 64 && k < 91) ||
    (k > 96 && k < 123) ||
    k === 8 ||
    k === 13 ||
    (k >= 48 && k <= 57)
  ) {
    return true;
  }
  e.preventDefault();
};

export const allowOnlyNumber = e => {
  const k = e.charCode;
  if (k === 8 || k === 13 || (k >= 48 && k <= 57)) {
    return true;
  }
  e.preventDefault();
};

export const stringIsOnlyAlphaNumeric = string =>
  !!string && /^[a-zA-Z0-9]*$/.exec(string);

export const stringIsOnlyNumeric = string =>
  !!string && /^[0-9]*$/.exec(string);

export const stringIsOfLength = (string, length) =>
  string && string.trim().length === length;

export const validateMaxStringLength = (string, length) =>
  string && string.trim().length <= length;

export const restrictStringLength = (e, length) => {
  const { value, selectionEnd, selectionStart } = e.target;
  if (
    value.length < length ||
    selectionEnd - selectionStart ||
    e.charCode === 13
  ) {
    return true;
  }
  e.preventDefault();
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const getAppDownloadLink = () => {
  let link = IOS_APP_DOWNLOAD_LINK;
  if (getMobileOperatingSystem() === 'Android') {
    link = ANDROID_APP_DOWNLOAD_LINK_HOME;
  }

  return link;
};

export const checkIfAndroidBtnShown = () => {
  const operatingSystem = getMobileOperatingSystem();
  let isAndroid = false;
  if (['Android', 'iOS'].indexOf(operatingSystem) === -1) {
    isAndroid = true;
  } else {
    isAndroid = operatingSystem === 'Android';
  }

  return isAndroid;
};

export const checkIfIOSBtnShown = () => {
  const operatingSystem = getMobileOperatingSystem();
  let isIOS = false;
  if (['Android', 'iOS'].indexOf(operatingSystem) === -1) {
    isIOS = true;
  } else {
    isIOS = operatingSystem === 'iOS';
  }

  return isIOS;
};

export const getSubstringsFromString = (string, startChar, endChar) => {
  const substrings = [];
  let newString;

  const extractString = (str, start, end) => {
    const substring = str.substring(str.indexOf(start) + 1, str.indexOf(end));
    substrings.push(substring);
    newString = str.substring(str.indexOf(end) + 1);

    if (newString.indexOf(start) > -1) {
      extractString(newString, start, end);
    }
  };
  if (string.indexOf(startChar) === -1) {
    return substrings;
  }
  extractString(string, startChar, endChar);

  return substrings;
};

export const calculatePercentageDeduction = (value, percentage) =>
  value - calculatePercentage(value, percentage);

export const calculatePercentage = (value, percentage) =>
  (value * percentage) / 100;

export const roundOff = (value, decimals) =>
  Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

export const calculateFlatFee = (value, fee) => value - fee;

export const isIntNumeric = num => !isNaN(parseInt(num)) && isFinite(num);
export const isNumeric = num => !isNaN(parseFloat(num)) && isFinite(num);

export const isBigNumber = num => {
  const NUMERIC = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;
  num += '';
  return NUMERIC.test(num);
};

export const customComparator = (selectedKeySortOrder, valueA, valueB) => {
  if (valueA === undefined && valueB === undefined) {
    return 0;
  }
  if (isBigNumber(valueA) && isBigNumber(valueB)) {
    const bigValA = new Big(valueA);
    const bigValB = new Big(valueB);
    return (
      (bigValB.gt(bigValA) ? 1 : bigValA.gt(bigValB) ? -1 : 0) *
      selectedKeySortOrder
    );
  }

  return (
    (valueB > valueA ? 1 : valueA > valueB ? -1 : 0) * selectedKeySortOrder
  );
};

export const isDateInRange = (
  dateToCheck,
  startDate = null,
  endDate = null
) => {
  let isBetweenDateRange = false;

  if (startDate && isDate(startDate)) {
    isBetweenDateRange = isAfter(dateToCheck, startDate);
  }

  if (endDate && isDate(endDate) && (!startDate || isBetweenDateRange)) {
    isBetweenDateRange = isBefore(dateToCheck, endDate);
  }

  return isBetweenDateRange;
};

/**
 *
 * @param {url} endpoint - The endpoint to ping to check for updates
 * @param {integer} interval - Polling interval
 * @param {function} handler - Success handler when update is detected.
 * This method works by sending the if-modified-since header containing the last-modified date of the current webpage.
 * If the server returns 304 Not modified status then no action is taken as there were no updates available on server after the if-modified-since date.
 * If the server returns 200 status code, it means that the website has been updated since the 'if-modified-since' date that we sent.
 * Success handler is invoked in the case when 200 status code is returned.
 */
export const checkForUpdates = (
  endpoint,
  modifiedHandler,
  notModifiedHandler,
  errorHandler
) => {
  let lastModified;
  try {
    if (/firefox/i.test(navigator.userAgent)) {
      /**
       * Added special condition for firefox due to the following bug:
       * https://bugzilla.mozilla.org/show_bug.cgi?id=1594411
       */
      lastModified = new Date(document.lastModified + ' GMT').toUTCString();
    } else {
      // This works for rest of the browsers (chrome/safari)
      lastModified = new Date(document.lastModified).toUTCString();
    }
  } catch (e) {
    lastModified = new Date(document.lastModified).toUTCString();
  }

  endpoint = endpoint || '/';
  fetch(endpoint, {
    method: 'GET',
    headers: {
      'if-modified-since': lastModified
    }
  })
    .then(response => {
      if (response.status === 200) {
        const lastModifiedHeader =
          response.headers && response.headers.get('last-modified');
        const lastModifiedHeaderDate =
          lastModifiedHeader && new Date(lastModifiedHeader);
        if (
          lastModifiedHeaderDate &&
          isAfter(lastModifiedHeader, lastModified)
        ) {
          modifiedHandler && modifiedHandler();
          return;
        }
      }
      notModifiedHandler && notModifiedHandler(response);
    })
    .catch(error => {
      errorHandler && errorHandler(error);
    });
};

// Platform Grouping Boolean Helper Methods

export const isMobileApp = platform =>
  [PLATFORM.ANDROID, PLATFORM.IOS].indexOf(platform) !== -1;

export const isWeb = platform =>
  [PLATFORM.WEB, '', null, undefined].indexOf(platform) !== -1;

export const isDesktopApp = platform =>
  [PLATFORM.WINDOWS, PLATFORM.MAC].indexOf(platform) !== -1;

export const convertToFormData = fields => {
  const formData = new FormData();
  Object.keys(fields).forEach(key => formData.append(key, fields[key]));
  return formData;
};

// CURRENCY FORMATTER
export const formatCurrency = (
  value,
  regexFormatter,
  params,
  showSymbol = false
) => {
  const stringifiedValue = value && value.toString();
  let formattedValue = stringifiedValue;
  const separatedValue = stringifiedValue && stringifiedValue.split('.');
  if (separatedValue && separatedValue.length >= 1) {
    const thousandsValue = separatedValue[0]
      ? separatedValue[0].replace(
          regexFormatter,
          `$1${params.thousandsSeparator}`
        )
      : '';
    const decimalValue = separatedValue[1]
      ? `${params.decimalSeparator}${separatedValue[1]}`
      : '';
    formattedValue = `${thousandsValue}${decimalValue}`;
  }

  return params.renderFormat
    .replace('%v', formattedValue)
    .replace('%s', showSymbol && params.symbol ? params.symbol : '')
    .trim();
};

export const formatIndianCurrency = (value, showSymbol) => {
  const regexFormatter = new RegExp(/(\d)(?=(\d{2})+(\d)(?!\d))/g);
  return formatCurrency(
    value,
    regexFormatter,
    INR_CURRENCY_DEFAULT_PARAMS,
    showSymbol
  );
};

export const formatGlobalCurrency = (
  value,
  isFiat,
  showSymbol,
  params = {}
) => {
  const regexFormatter = new RegExp(/(\d)(?=(\d{3})+(?!\d))/g);
  params = {
    ...(isFiat ? FIAT_CURRENCY_DEFAULT_PARAMS : CRYPTO_CURRENCY_DEFAULT_PARAMS),
    ...params
  };
  return formatCurrency(value, regexFormatter, params, showSymbol);
};

export const getStep = precision => {
  // Raise base 10 to exponent precision and divide 1 by this number.
  return 1 / Math.pow(10, precision);
};

export const customLazy = (
  fn,
  endpoint = '/',
  retriesLeft = 3,
  interval = 1000
) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            checkForUpdates(endpoint, updateHandler, reject, updateHandler);
          } else {
            // Passing on "reject" is the important part
            customLazy(fn, endpoint, retriesLeft - 1, interval).then(
              resolve,
              reject
            );
          }
        }, interval);
      });
  });
};

export const getSubDomain = () => {
  return (window && window.location.host.split('.')[0]) || '';
};

export const isMoneyControlPage = () => {
  // remove money control integration since contract is over
  // this method controls all the moneycontrol related UI
  // when contract renews, simply turn the IS_MONEYCONTROL_DEACTIVATED off

  return !IS_MONEYCONTROL_DEACTIVATED && getSubDomain() === 'moneycontrol';
};

export const setQueryParams = (search, key, value) => {
  let queryParamsObject = queryString.parse(search || '');
  queryParamsObject[key] = value;
  return '?' + decodeURIComponent(queryString.stringify(queryParamsObject));
};

const updateHandler = () => {
  window && window.location.reload(true);
};

export const isSafariBrowser = () => {
  return (
    navigator?.userAgent &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );
};

export const redirectToDeepLink = () => {
  if (window?.location) {
    // Docs for below link format - https://firebase.google.com/docs/dynamic-links/create-manually
    let mainLink = window.location.href;
    const searchParams = new URLSearchParams(window.location.search);

    // If URL contains returnTo query param then update the main link url for deeplink
    if (searchParams?.get('returnTo')) {
      mainLink = `${window.location.origin}${searchParams.get('returnTo')}`;
    }

    window.location.href = `https://${MOBILE_PAGE_LINK}/?link=${mainLink}&apn=${MOBILE_APP_APN}&ibi=${MOBILE_APP_IBI}&isi=${MOBILE_APP_ISI}`;
  }
};

export const detectBrowser = () => {
  if (navigator) {
    if (navigator?.brave) {
      return 'brave';
    } else {
      const parsed = UAParser(navigator.userAgent);
      return parsed?.browser?.name?.toLowerCase() ?? null;
    }
  }
  return null;
};

export const isUAMobileDevice = () => {
  if (navigator) {
    const parsed = UAParser(navigator.userAgent);
    return parsed?.device?.type === 'mobile';
  }
  return false;
};

export const getTimeZoneTradingViewCharts = () => {
  // Bug in Chrome - Asia/Calcutta is yet to be renamed in Chrome browser but it has been fixed in other browser
  // https://bugs.chromium.org/p/chromium/issues/detail?id=580195
  const TRADING_VIEW_SUPPORTED_TIMEZONE_MAP = {
    'Asia/Calcutta': 'Asia/Kolkata'
  };

  const detectedTimezone =
    Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Asia/Kolkata';

  return (
    TRADING_VIEW_SUPPORTED_TIMEZONE_MAP[detectedTimezone] || detectedTimezone
  );
};

export const generateWindowHeightForMobileView = (
  deviceHeight, // mobile viewport height
  topHeight, // the buffer from the top - includes custom message, zero-day message heights if they're shown
  isHeaderVisible, // is the top header wazirx navbar visible
  isBottomTabBarVisible, // do we show bottom tabs like P2P, Mobile exchange?
  customComponentHeight, // any custom component height to be subtracted in case the feature requires,
  customBottomPadding = 15 //extra padding, in case required
) => {
  const finalPanelHeight =
    toValidNumberOrZero(deviceHeight) -
    ((isHeaderVisible ? MOBILE_NAVBAR_HEIGHT : 0) + //top navbar header
    toValidNumberOrZero(customComponentHeight) + //1st page and logged-in, don't show the login header
    (isBottomTabBarVisible ? MOBILE_BOTTOM_TABS_HEIGHT : 0) + // bottom bar tabs
      toValidNumberOrZero(topHeight)) +
    toValidNumberOrZero(customBottomPadding); //extra padding; // final panel height after all the computations (50-header, 50-currencySelection/login-signup tab, 60-bottomTabs)

  return finalPanelHeight;
};

export const getEmailClientForEmail = email => {
  let DEFAULT_EMAIL_CLIENT = 'gmail';
  const EMAIL_CLIENT_OPTIONS = ['yahoo', 'gmail', 'outlook', 'hotmail', 'aol'];

  const emailStringArr = email?.toLowerCase()?.split('@') ?? [];

  if (emailStringArr.length > 1) {
    EMAIL_CLIENT_OPTIONS.forEach(emailClient => {
      if (emailStringArr[1].startsWith(emailClient)) {
        DEFAULT_EMAIL_CLIENT = emailClient;
      }
    });
  }
  return `https://${DEFAULT_EMAIL_CLIENT}.com`;
};

window._setTheme = () => {
  // no-op
  // added to ensure backward compatibility
  // added this so that mobile doesn't get undefined function till dark-mode goes live
  // to be removed when dark mode goes live
};
