import React from 'react';
import { withTranslation } from 'react-i18next';

import Text from '../components/common/Text';
import TooltipContainer from '../components/common/TooltipContainer';
import { InfoIcon } from '../components/SavedAddress/AddSavedAddress/AddSavedAddress.style';

import COLORS from '../components/common/helpers/colors';

export const portfolioAmountReadPrecision = value => {
  // 12.5432566 becomes 12.54
  // and 0.000045562 becomes 0.000045
  // and 34.0000004522 becomes 34
  // 0.0000000000000005 = 0.0000000000000005
  // 0.00000000000000055 = 0.00000000000000055
  // 0.000000000000000543 = 0.00000000000000054

  // Set Default Value
  var maxAllowedDecimal = 0;

  value = parseFloat(value);

  if (value >= 100) {
    // If value is greater than 100, hide decimals
    maxAllowedDecimal = 0;
  } else if (value > 0 && value < 1) {
    // Need to display 2 most significant digits

    // Validation
    let valueArray = value?.toString?.()?.split('.');
    if (!valueArray.length) return 0;

    if (valueArray.length === 2) {
      // Decimals found
      let decimals = valueArray[1]; //trailing decimal portion after "."

      let found2Decimals = false;
      for (
        let indexOfDigit = 0;
        indexOfDigit < decimals.length && !found2Decimals;
        indexOfDigit++
      ) {
        if (decimals[indexOfDigit] !== '0') {
          maxAllowedDecimal = indexOfDigit + 1; // Set max allowed decimal = indexOfDigit. 1 is added as arrays start at index 0.

          if (decimals.length >= indexOfDigit + 1) {
            // If there are 2 significant digits then we should dispay 2 significant digits.
            maxAllowedDecimal += 1;
          }

          found2Decimals = true; // No further requirement to loop through digits once we have found max allowed decimals to display.
        }
      }
    }
  } else {
    // Decimal value > 1 && value < 100
    maxAllowedDecimal = 2;
  }

  return maxAllowedDecimal;
};

export const portfolioPercReadPrecision = value => {
  value = parseFloat(value);
  if (value >= 100) {
    // If value is greater than 100, hide decimals
    return 0;
  }
  return 2;
};

export const BalanceUpdating = withTranslation()(
  ({ t, fund, isBalanceUpdating, children }) => {
    return (
      <>
        {fund?.get?.('isBalanceUpdating') || isBalanceUpdating ? (
          <Text medium fontStyle="italic" inherit noWrap>
            <PnLToolTip isBalancesUpdating />{' '}
            {t('funds.portfolioOverview.balancesUpdating')}
          </Text>
        ) : (
          children
        )}
      </>
    );
  }
);

export const PnLToolTip = withTranslation()(
  ({
    isTotalPortfolio,
    isCryptoHoldings,
    isInvestedValue,
    isAllTimePnl,
    isBalancesUpdating,
    t
  }) => {
    return (
      <TooltipContainer
        content={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Text medium color={COLORS.white}>
            {isTotalPortfolio &&
              t('funds.portfolioOverview.totalPortfolioTooltip')}
            {isCryptoHoldings &&
              t('funds.portfolioOverview.cryptoHoldingsToolTip')}
            {isInvestedValue &&
              t('funds.portfolioOverview.investedValueToolTip')}
            {isAllTimePnl && t('funds.portfolioOverview.allTimePnlToolTip')}
            {isBalancesUpdating &&
              t('funds.portfolioOverview.balancesUpdatingToolTip')}
          </Text>
        }
      >
        <InfoIcon className="mdi mdi-information-outline" />
      </TooltipContainer>
    );
  }
);
