import * as Sentry from '@sentry/browser';
//all helpers around the sentry library and handling it's checks if any

export const checkMutedExceptions = (event, hint) => {
  let muteExceptionFl = false; // muted ? true : false

  //conditions for muting exceptions
  try {
    if (
      hint.originalException === 'Timeout' ||
      checkForExpression(
        hint,
        'Non-Error promise rejection captured with value: Timeout'
      )
    ) {
      // https://sentry.io/share/issue/84c62dd4ec954f81ba0534670af4fde1/
      // These are caused by a timeout in google recaptcha and do not affect the end-user in any way.
      // Hence muting these errors at source to not consume our quota.
      // Refer to: https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338

      muteExceptionFl = true;
    } else if (checkForExpression(hint, 'IDBDatabase')) {
      // https://wazirx.atlassian.net/browse/WAZ-4345
      // https://sentry.io/share/issue/0e4c5dc558c0409e9f07f2cd0e46bfc0/
      // iOS issue : Error: InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase'

      muteExceptionFl = true;
    } else if (checkForExpression(hint, 'getReadMode')) {
      // https://wazirx.atlassian.net/browse/WAZ-4345
      // getReadMode error : TypeError: Cannot read property 'getReadModeExtract' of undefined
      // https://sentry.io/share/issue/a0c980dc3aa04b559b283791ba3adab3/
      // https://sentry.io/share/issue/3a1ab94dfb3d4b64a957c6957a739a40/
      // https://sentry.io/share/issue/7c09297a27324256a4c7b02fadb9960c/
      muteExceptionFl = true;
    } else if (checkForExpression(hint, 'sendBeacon')) {
      // https://wazirx.atlassian.net/browse/WAZ-4345
      // https://sentry.io/share/issue/575e1340f22c44adad3e65e352e9816d/
      //  SecurityError: Failed to execute 'sendBeacon' on 'Navigator': sendBeacon()...

      muteExceptionFl = true;
    }
  } catch (e) {
    //exception
  }

  return muteExceptionFl;
};

//regexp checked. to reduce verbose code
const checkForExpression = (hint, exp) => {
  let expression = new RegExp(exp, 'i');
  if (
    hint &&
    hint.originalException &&
    hint.originalException.message &&
    hint.originalException.message.match(expression)
  ) {
    return true;
  } else {
    return false;
  }
};

// list of all the urls which should not log any errors/trace in sentry
export const sentryDenyUrls = () => [
  'https://api-js.mixpanel.com/',
  'https://www.google-analytics.com',
  'https://stats.g.doubleclick.net'
];

// list of errors which should be ignored by sentry
export const sentryIgnoreErrors = () => [
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: cancelled',
  'TypeError: The network connection was lost.'
];

//set the user details in sentry to identify the user
export const setSentryUserDetails = userDetails => {
  const sn = userDetails?.sn;
  if (sn) {
    setTimeout(() => {
      Sentry.setUser({
        id: sn
      });
    });
  }
};

//reset the user details from sentry on logout
export const clearSentryUserDetails = () => {
  setTimeout(() => {
    Sentry.configureScope(scope => scope.setUser(null));
  });
};
