import React from 'react';
import { getFromLocalStorage, setToLocalStorage } from './localStorageHelpers';
import { THEME } from '../constants/Core';

// detect the current theme
export const detectTheme = () => {
  const theme =
    getFromLocalStorage('theme') || //if the theme is set in local-storage, use that
    (window.matchMedia && //else, detect system theme - in case of dark
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      THEME.DARK) ||
    THEME.LIGHT; //else revert to default

  return theme;
};

// the context object which contains the theme iside the app
export const ThemeContext = React.createContext({
  theme: detectTheme(),
  toggleTheme: () => {}
});

// only sets the class name, depending on the value in storage
export const setThemeInRootClass = (theme, disableThemeOverride) => {
  var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)

  let classNames = root.getAttribute('class');
  const newThemeClass = `theme-${theme}`;

  //create an array out of the classes string
  classNames = classNames.split(' ');

  var themeClassIndex = classNames.findIndex(
    className => className.indexOf('theme-') > -1
  );

  if (themeClassIndex > -1) {
    //if theme class found, replace it with new theme class
    classNames[themeClassIndex] = newThemeClass;
  } else {
    //push the new theme class
    classNames.push(newThemeClass);
  }

  var disableThemeOverrideClassIndex = classNames.findIndex(
    className => className.indexOf('disableThemeOverride') > -1
  );

  // disable theme override logic
  if (disableThemeOverride) {
    //disable override theme is present on page
    if (disableThemeOverrideClassIndex > -1) {
      // class is also present
      // no-op
    } else {
      classNames.push('disableThemeOverride');
    }
  } else {
    //disable override theme is NOT present on page
    if (disableThemeOverrideClassIndex > -1) {
      // class is present, it shouldn't be
      classNames[disableThemeOverrideClassIndex] = ''; //reset the class
    } else {
      // class is not present
      // no-op
    }
  }

  root.setAttribute('class', classNames.join(' '));
};

// set the theme of app
export const setTheme = theme => {
  setToLocalStorage('theme', theme);
  setThemeInRootClass(theme);

  // window.alert(`Setting theme using method ${theme}`);
};

window._setTheme = setTheme;

// get the color from the design tokens present in the browser
export const getColorFromTheme = color => {
  return (
    window
      ?.getComputedStyle?.(document.documentElement)
      ?.getPropertyValue?.(color)
      ?.trim() || ''
  );
};
