export const setToLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    // Ignore this;
  }
  window.WAZIRX = window.WAZIRX || {};
  window.WAZIRX[key] = value;
};

export const removeFromLocalStorage = key => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    // Ignore this;
  }

  if (window.WAZIRX) {
    window.WAZIRX[key] = undefined;
  }
};

export const getFromLocalStorage = key => {
  window.WAZIRX = window.WAZIRX || {};

  if ([null, undefined].indexOf(window.WAZIRX[key]) === -1) {
    //if window.WAZIRX[key] is found
    return window.WAZIRX[key];
  } else {
    try {
      // fetch the key from localStorage, and also set it in the window object
      // Done for https://wazirx.atlassian.net/browse/WAZ-4344
      // https://sentry.io/share/issue/0738de57834746b594f7b9db6a1fcec8/
      // Unable to access localStorage in some clients.
      window.WAZIRX[key] =
        window.WAZIRX[key] || window.localStorage.getItem(key);
    } catch (e) {
      // Ignore this;
      window.WAZIRX[key] = null;
    }
  }

  return window.WAZIRX[key];
};

export const setJSONObjectToLocalStorage = (key, value) => {
  removeFromLocalStorage(key);
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // Ignore this;
  }

  window.WAZIRX = window.WAZIRX || {};
  window.WAZIRX[key] = value;
};

export const getJSONObjectFromLocalStorage = key => {
  window.WAZIRX = window.WAZIRX || {};

  if (!window.WAZIRX[key]) {
    try {
      const item = window.localStorage.getItem(key);
      if (!item) {
        return undefined;
      }
      window.WAZIRX[key] = JSON.parse(item);
    } catch (ex) {
      return window.WAZIRX[key];
    }
  }
  return window.WAZIRX[key];
};
