import { fromJS } from 'immutable';

import * as types from '../constants/ActionTypes';

const initialState = fromJS([]);

export default function(state = initialState, action) {
  switch (action.type) {
    case types.NOTIFICATION_SEND:
      if (action.data.message && action.data.type) {
        return state.push(fromJS(action.data));
      }
      return state;

    case types.NOTIFICATION_DISMISS:
      return state.filter(message => message.get('id') !== action.id);

    default:
      return state;
  }
}
