import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import { RESET_PASSWORD_INITIATE_SUCCESS } from "../constants/MessageCodes";

const initialState = fromJS({
  initiate: {
    isSending: false,
    isSendingError: false,
    error: null,
    data: null
  },
  verify: {
    isSending: false,
    isSendingError: false,
    error: null,
    data: null
  },
  reset: {
    isSending: false,
    isSendingError: false,
    error: null,
    data: null
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.INITIATE_RESET_PASSWORD_INIT:
      return state.mergeIn(['initiate'], {
        isSending: true,
        isSendingError: false
      });

    case types.INITIATE_RESET_PASSWORD_SUCCESS:
      return state.mergeIn(['initiate'], {
        isSending: false,
        isSendingError: false,
        error: null,
        data: RESET_PASSWORD_INITIATE_SUCCESS
      });

    case types.INITIATE_RESET_PASSWORD_ERROR:
      return state.mergeIn(['initiate'], {
        isSending: false,
        isSendingError: true,
        error: action.error,
        data: null
      });

    case types.VERIFY_TOKEN_INIT:
      return state.mergeIn(['verify'], {
        isSending: true,
        isSendingError: false
      });

    case types.VERIFY_TOKEN_SUCCESS:
      return state.mergeIn(['verify'], {
        isSending: false,
        isSendingError: false,
        error: null,
        data: RESET_PASSWORD_INITIATE_SUCCESS
      });

    case types.VERIFY_TOKEN_ERROR:
      return state.mergeIn(['verify'], {
        isSending: false,
        isSendingError: true,
        error: action.error,
        data: null
      });

    case types.RESET_PASSWORD_INIT:
      return state.mergeIn(['reset'], {
        isSending: true,
        isSendingError: false
      });

    case types.RESET_PASSWORD_SUCCESS:
      return state.mergeIn(['reset'], {
        isSending: false,
        isSendingError: false,
        error: null,
        data: {}
      });

    case types.RESET_PASSWORD_ERROR:
      return state.mergeIn(['reset'], {
        isSending: false,
        isSendingError: true,
        error: action.error,
        data: null
      });

    default:
      return state;
  }
}
