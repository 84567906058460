import React from 'react';
import errorBench from '../../images/error-bench.svg';

import '../../style/components/_errorPage.scss';

const ErrorPage = () => {
  return (
    <div className="errorPage">
      <div className="errorPage-hero">
        <img src={errorBench} alt="error page" />
      </div>
      <span className="errorPage-title">Something went wrong!</span>
      <span className="errorPage-description">
        Take a few deep breaths and hit &quot;Refresh&quot;.
      </span>
      <button
        type="submit"
        className="errorPage-button"
        onClick={() => window && window.location.reload(true)}
      >
        Refresh
      </button>
    </div>
  );
};

export default ErrorPage;
