import * as types from '../constants/ActionTypes';

const initialState = {
  userSettings: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.key]: action.value
        }
      };
    default:
      return state;
  }
}
