import { format } from 'date-fns';
import { GIFT_MODE, GIFT_STATUS } from '../../constants/Core';
import { toRound } from '../core';
import { getCurrencyPrecision } from './common';
import { formatQuoteCurrency } from './exchange';

export const normalizeSingleGift = (
  {
    senderName,
    message,
    receiverEmail,
    updatedAt,
    currency,
    status,
    amount,
    artworkUrl,
    id,
    createdAt
  },
  mode
) => {
  //   STATUS = {
  //     INITIATED: 0,
  //     SENT: 1,
  //     ACCEPTED: 2,
  //     REJECTED: 3,
  //     CANCELLED: 98,
  // }

  const _isReceived = mode ? mode === GIFT_MODE.RECEIVED : undefined; // in case of single gift details (mode undefined), you won't be able to know if gift was sent or received. For that you need to check in container.

  const _isOpened =
    status === GIFT_STATUS.ACCEPTED || status === GIFT_STATUS.REJECTED;

  const _giftDateFormatted = format(
    new Date(createdAt),
    'dd MMM yyyy, hh:mm a'
  );

  const _giftValueFormatted = formatQuoteCurrency(
    toRound(amount, getCurrencyPrecision(currency)),
    currency,
    true
  )?.toString?.();

  return {
    senderName,
    message,
    receiverEmail,
    updatedAt,
    currency,
    status,
    amount,
    artworkUrl,
    id,
    createdAt,

    // One's to be used for display and logic
    _isReceived,
    _isOpened,
    _giftDateFormatted,
    _giftValueFormatted
  };
};

export const normalizeGiftList = (gifts, mode = GIFT_MODE.RECEIVED) =>
  gifts
    .sort((giftA, giftB) =>
      new Date(giftA?.createdAt) < new Date(giftB?.createdAt) ? 1 : -1
    )
    .map(gift => normalizeSingleGift(gift, mode));
