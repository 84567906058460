import { format } from 'date-fns';
import Big from 'big.js';
import { fromJS } from 'immutable';
import _keys from 'lodash/keys';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import { toFixed, formatIndianCurrency, formatGlobalCurrency } from '../core';
import {
  LIMIT_API_RESPONSE,
  CURRENCY,
  CURRENCY_TYPE
} from '../../constants/Core';
import { getJSONObjectFromLocalStorage } from '../localStorageHelpers';
import {
  getBasePrecision,
  getQuotePrecision,
  getCurrencyFromType
} from './common';
import { portfolioAmountReadPrecision } from '../portfolioHelpers';

export const isExchangeSupported = key => {
  const config = getJSONObjectFromLocalStorage('config');
  return !!config.markets[key];
};

export const getValidMarkets = () => {
  const config = getJSONObjectFromLocalStorage('config');
  const markets =
    config &&
    config.markets &&
    filter(config.markets, market => !market.isDelisted && !market.isHidden);
  return markets || [];
};

export const getCurrencyNameFromType = key => {
  const config = getJSONObjectFromLocalStorage('config');
  return (
    config &&
    config.currencies &&
    config.currencies[key] &&
    config.currencies[key].name
  );
};

export const quoteCurrenciesFromConfig = () => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.quoteCurrencies) {
    return config.quoteCurrencies;
  }
  if (config && config.markets) {
    return uniqBy(Object.values(config.markets), 'quoteMarket').map(
      currency => currency.quoteMarket
    );
  }
  return CURRENCY.INR;
};

export const getPreferredCurrenciesFromConfig = () => {
  // This method currently returns all the currencies that are either quote or fiat as preferred currencies.
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.currencies) {
    return filter(
      config.currencies,
      currency => currency.cryptoRate && currency.cryptoRate.isPreferredQuote
    );
  }
  return null;
};

export const isMarketActive = key => {
  const config = getJSONObjectFromLocalStorage('config');
  return (
    config &&
    config.markets &&
    config.markets[key] &&
    !config.markets[key].isDelisted
  );
};

export const supportedExchange = () => {
  const config = getJSONObjectFromLocalStorage('config');
  return config && config.currencies && Object.keys(config.currencies);
};

export const getBaseAndQuoteCurrencyFromMarket = marketType => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.markets) {
    const selectedMarket = config.markets[marketType];
    return (
      selectedMarket && {
        quote: selectedMarket.quoteMarket,
        base: selectedMarket.baseMarket
      }
    );
  }
  return { quote: CURRENCY.INR, base: CURRENCY.BTC };
};

export const getIndianFormatRupee = price => {
  let number;
  price = price.toString();
  number = price.split('.');
  const hasDecimal = price.indexOf('.') > -1;
  const decimal = number[1];
  number = number[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  if (decimal || hasDecimal) {
    price = `${number}.${decimal}`;
  } else {
    price = number;
  }
  return price;
};

export const formatMarketCurrencyWithPrecision = (
  value,
  showCurrencySymbol,
  base,
  quote = null,
  customPrecision
) => {
  const isCustomPrecision =
    [null, undefined, ''].indexOf(customPrecision) === -1;

  if (!quote) {
    return formatQuoteCurrency(
      toFixed(
        value,
        isCustomPrecision ? customPrecision : getBasePrecision(base)
      ),
      base,
      showCurrencySymbol
    );
  }
  return formatQuoteCurrency(
    toFixed(
      value,
      isCustomPrecision ? customPrecision : getQuotePrecision(base, quote)
    ),
    quote,
    showCurrencySymbol
  );
};

export const formatQuoteCurrency = (
  value,
  quote,
  showCurrencySymbol = false
) => {
  const formattedValue =
    ['', 0, null, undefined, '0', '0.0', '.'].indexOf(value) === -1 ? value : 0;

  if (CURRENCY.INR === quote) {
    return formatIndianCurrency(formattedValue, showCurrencySymbol);
  }

  const currency = getCurrencyFromType(quote);
  if (currency) {
    const params = {
      symbol: currency.symbol
    };
    return formatGlobalCurrency(
      formattedValue,
      currency.category === CURRENCY_TYPE.FIAT,
      showCurrencySymbol,
      params
    );
  }

  return formatGlobalCurrency(formattedValue, false, showCurrencySymbol);
};

export const normalizeExchangeQuoteCurrency = markets =>
  uniqBy(_keys(markets).map(currency => markets[currency].quote_unit));

export const normalizeExchangeCurrency = markets => {
  let exchange = {};
  const exchangeData = {};
  Object.keys(markets).forEach(currency => {
    if (isExchangeSupported(currency)) {
      exchange = markets[currency];
      const market = `${exchange.base_unit.toLowerCase()}${exchange.quote_unit.toLowerCase()}`;
      const allowedExchangeDecimal = getBasePrecision(
        exchange.base_unit.toLowerCase()
      );
      const allowedDecimal = getQuotePrecision(
        exchange.base_unit.toLowerCase(),
        exchange.quote_unit.toLowerCase()
      );
      const open = new Big(exchange.open);
      const last = new Big(exchange.last);
      const volume = new Big(exchange.volume);
      const bigZero = new Big(0);
      const change = !open.eq(bigZero)
        ? last
            .minus(open)
            .div(open)
            .times(100)
        : bigZero;
      exchangeData[currency] = {
        name: getCurrencyNameFromType(exchange.base_unit),
        symbol: exchange.base_unit.toUpperCase(),
        base: exchange.quote_unit.toLowerCase(),
        pair: `${exchange.base_unit.toUpperCase()}-${exchange.quote_unit.toUpperCase()}`,
        price: toFixed(last, allowedDecimal),
        open,
        high: toFixed(new Big(exchange.high), allowedDecimal),
        low: toFixed(new Big(exchange.low), allowedDecimal),
        close: last,
        volume: toFixed(volume, allowedExchangeDecimal),
        quoteVolume: toFixed(last.times(volume), allowedDecimal),
        date: new Date(),
        exchange: exchange.base_unit.toLowerCase(),
        market,
        changeAmount: toFixed(last.minus(open), allowedDecimal),
        change: parseFloat(change.toFixed(2))
      };
    }
  });
  return exchangeData;
};

export const normalizeLiveTradeHistory = (trades, newTrades, currentMarket) => {
  trades = trades || fromJS([]);
  const { price, tid, amount, date, market } = newTrades[0];
  const doesTradeExist =
    trades.findIndex(trade => trade.get('id') === tid) > -1;

  // 1. Sometimes it may happen that some trades that were sent in api are also sent in socket and this causes duplicate records.
  // To avoid duplicate records to be shown in the UI, we make a check in the existing list.
  // 2. Sometimes, It may also happen that when a user switches the markets, some events that we receive are from the previous markets.
  // To avoid these events from being shown in the chart of new markets, we have added a check to make sure the market user has opened
  // is the same as the market whose event we have received.

  if (!doesTradeExist && market === currentMarket) {
    const { base, quote } = getBaseAndQuoteCurrencyFromMarket(currentMarket);
    const quotePrecision = getQuotePrecision(base, quote);
    const basePrecision = getBasePrecision(base);
    const prevTrade = trades && trades.get(0);
    let refValue = 1;
    if (prevTrade) {
      const prevPrice = prevTrade.get('price');
      const parsedCurrentPrice = new Big(price);
      if (parsedCurrentPrice.eq(prevPrice)) {
        refValue = prevTrade.get('refValue');
      } else {
        refValue = parsedCurrentPrice.gt(prevPrice) ? 1 : -1;
      }
    }
    trades = trades.unshift(
      fromJS({
        refValue,
        id: tid,
        time: format(new Date(date * 1000), 'HH:mm:ss'),
        price: toFixed(new Big(price), quotePrecision),
        volume: toFixed(new Big(amount), basePrecision)
      })
    );
    if (trades.size > LIMIT_API_RESPONSE) {
      trades = trades.pop();
    }
  }
  return trades;
};

export const normalizeTradeHistory = (trades, market) => {
  const { base, quote } = getBaseAndQuoteCurrencyFromMarket(market);
  const quotePrecision = getQuotePrecision(base, quote);
  const basePrecision = getBasePrecision(base);
  const data = [];
  let prevTrade;
  for (let ti = trades.length - 1; ti >= 0; ti--) {
    const { id, created_at, price, volume } = trades[ti];
    let refValue = 1;
    if (prevTrade) {
      const parsedCurrentPrice = new Big(price);
      if (parsedCurrentPrice.eq(prevTrade.price)) {
        refValue = prevTrade.refValue;
      } else {
        refValue = parsedCurrentPrice.gt(prevTrade.price) ? 1 : -1;
      }
    }

    data.unshift({
      refValue,
      id,
      time: format(new Date(created_at), 'HH:mm:ss'),
      price: toFixed(new Big(price), quotePrecision),
      volume: toFixed(new Big(volume), basePrecision)
    });
    if (data.length > LIMIT_API_RESPONSE) {
      data.pop();
    }
    prevTrade = data[0];
  }
  return data;
};

export const formatGainsWithSymbol = (
  gain,
  showCurrencySymbol,
  userPreferredCurrency
) => {
  const sign = gain >= 0 ? '+' : '-';
  const value = Math.abs(gain || 0);

  return `${sign} ${formatMarketCurrencyWithPrecision(
    value,
    showCurrencySymbol,
    userPreferredCurrency,
    null,
    portfolioAmountReadPrecision(value)
  )}`;
};
