import i18n from 'i18next';
import Fetch from 'i18next-fetch-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AVAILABLE_LANGUAGES } from './constants/Core';

const availableLanguages = AVAILABLE_LANGUAGES.map(lang => lang.value);
const fallbackLng = 'en-US';

i18n
  .use(Fetch) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false,
    load: 'currentOnly',
    keySeparator: '.',
    whitelist: availableLanguages,
    detection: {
      order: [
        'querystring',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      lookupLocalStorage: 'lang',
      lookupQuerystring: 'lang'
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?v90' //Update the version for now, whenever anything gets changed in translation.json.
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
