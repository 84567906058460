import Big from 'big.js';
import { format } from 'date-fns';
import {
  ZERO_VALUES,
  EMPTY_VALUES,
  ORDER_STATES,
  TRADE_STATES,
  SIDE,
  DERIVED_ORDER_STATES,
  ORDER_VIEW_TYPE
} from '../../constants/Core';
import {
  getQuotePrecision,
  normalizePaymentOptionsById,
  getBasePrecision,
  joinMarketWithSeparator
} from './common';
import { toFixed } from '../core';
import { getJSONObjectFromLocalStorage } from '../localStorageHelpers';

export const normalizeTrades = (trade, { tdsRate }) => {
  const price =
    ZERO_VALUES.indexOf(trade.price) === -1 ? new Big(trade.price) : new Big(0);

  const amount =
    ZERO_VALUES.indexOf(trade.amount) === -1
      ? new Big(trade.amount)
      : new Big(0);
  const volume =
    ZERO_VALUES.indexOf(trade.volume) === -1
      ? new Big(trade.volume)
      : new Big(0);
  const autoCancelsAt =
    EMPTY_VALUES.indexOf(trade.autoCancelsAt) === -1
      ? new Date(trade.autoCancelsAt)
      : undefined;
  const autoExpiresAt =
    EMPTY_VALUES.indexOf(trade.autoExpiresAt) === -1
      ? new Date(trade.autoExpiresAt)
      : undefined;

  const sellerPaymentOptions =
    EMPTY_VALUES.indexOf(trade.sellerPaymentOptions) === -1
      ? normalizePaymentOptionsById(trade.sellerPaymentOptions)
      : undefined;

  const createdAt =
    EMPTY_VALUES.indexOf(trade.createdAt) === -1
      ? new Date(trade.createdAt)
      : undefined;

  const askFee =
    ZERO_VALUES.indexOf(trade.askFee) === -1
      ? new Big(trade.askFee)
      : new Big(0);
  const bidFee =
    ZERO_VALUES.indexOf(trade.bidFee) === -1
      ? new Big(trade.bidFee)
      : new Big(0);
  const bidFeeMultiplier =
    ZERO_VALUES.indexOf(trade.bidFeeMultiplier) === -1
      ? new Big(trade.bidFeeMultiplier)
      : new Big(0);
  const askFeeMultiplier =
    ZERO_VALUES.indexOf(trade.askFeeMultiplier) === -1
      ? new Big(trade.askFeeMultiplier)
      : new Big(0);

  const tdsAmountMultiplier =
    ZERO_VALUES.indexOf(tdsRate) === -1 ? new Big(tdsRate) : new Big(0);

  const tdsAmount =
    ZERO_VALUES.indexOf(trade.askTdsAmount) === -1
      ? new Big(trade.askTdsAmount)
      : new Big(0);

  const tdsCurrency =
    trade?.askTdsCurrency || trade?.bidTdsCurrency || trade?.baseUnit;

  return {
    ...trade,
    sellerPaymentOptions,
    price,
    amount,
    volume,
    autoCancelsAt,
    autoExpiresAt,
    askFee,
    bidFee,
    bidFeeMultiplier,
    askFeeMultiplier,
    tdsAmount,
    tdsAmountMultiplier,
    tdsCurrency,
    createdAt
  };
};
export const normalizeP2POrderDetails = data => {
  const matchedVolume =
    ZERO_VALUES.indexOf(data.matchedVolume) === -1
      ? new Big(data.matchedVolume)
      : new Big(0);
  const originalVolume =
    ZERO_VALUES.indexOf(data.originalVolume) === -1
      ? new Big(data.originalVolume)
      : new Big(0);
  const filledVolume =
    ZERO_VALUES.indexOf(data.filledVolume) === -1
      ? new Big(data.filledVolume)
      : new Big(0);
  const price =
    ZERO_VALUES.indexOf(data.price) === -1 ? new Big(data.price) : new Big(0);

  const tdsRate =
    ZERO_VALUES.indexOf(data.askTdsRate || data.bidTdsRate) === -1
      ? new Big(data.askTdsRate || data.bidTdsRate)
      : new Big(0);

  const trades =
    data.trades &&
    data.trades.map(trade => normalizeTrades(trade, { tdsRate }));
  const createdAt =
    EMPTY_VALUES.indexOf(data.createdAt) === -1
      ? new Date(data.createdAt)
      : undefined;

  const progress = filledVolume.eq(0)
    ? 0
    : toFixed(filledVolume.div(originalVolume).mul(100), 2);
  let derivedOrderState;
  switch (data.state) {
    case ORDER_STATES.DONE:
      derivedOrderState = DERIVED_ORDER_STATES.COMPLETE;
      break;
    case ORDER_STATES.CANCEL:
      if (filledVolume.gt(0) && filledVolume.lt(originalVolume)) {
        derivedOrderState = DERIVED_ORDER_STATES.PARTIALLY_COMPLETE;
      } else {
        derivedOrderState = DERIVED_ORDER_STATES.CANCELLED;
      }
      break;
    case ORDER_STATES.WAIT:
      derivedOrderState = DERIVED_ORDER_STATES.OPEN;
      break;
    default:
      derivedOrderState = DERIVED_ORDER_STATES.UNKNOWN;
      break;
  }
  const pendingActions =
    (trades &&
      trades.length > 0 &&
      trades.filter(trade => {
        const { state, bidFundTransferred, askFundReceived } = trade;
        const { side } = data;

        if (side === SIDE.BUY) {
          return state === TRADE_STATES.WAIT && !bidFundTransferred;
        }

        if (side === SIDE.SELL) {
          return (
            (state === TRADE_STATES.WAIT &&
              bidFundTransferred &&
              !askFundReceived) ||
            state === TRADE_STATES.DISPUTE
          );
        }
        return false;
      }).length) ||
    0;

  return {
    ...data,
    matchedVolume,
    originalVolume,
    filledVolume,
    progress,
    derivedOrderState,
    price,
    trades,
    createdAt,
    pendingActions
  };
};
export const normalizeOrdersList = (orders, orderViewType) => {
  const normalizedOrders = orders.map(order => normalizeP2POrderDetails(order));
  if (orderViewType === ORDER_VIEW_TYPE.ACTIVE) {
    normalizedOrders.sort((a, b) => b.pendingActions - a.pendingActions);
  }
  return normalizedOrders;
};

export const normalizeP2POrderBook = (orders, selectedMarket, limit = null) => {
  const book = {};
  const { base, quote } = getBaseAndQuoteCurrencyFromP2PMarket(selectedMarket);
  const allowedDecimal = getQuotePrecision(base, quote);
  const currentDate = Date.now();

  if (limit) {
    if (orders.asks && orders.asks.length > 0) {
      orders.asks = orders.asks.slice(0, limit);
    }
    if (orders.bids && orders.bids.length > 0) {
      orders.bids = orders.bids.slice(0, limit);
    }
  }
  book.asks = orders.asks.map((order, index) => ({
    id: currentDate + index,
    price: toFixed(order[0], allowedDecimal),
    volume: order[1],
    username: order[3]
  }));

  book.bids = orders.bids.map((order, index) => ({
    id: currentDate + index,
    price: toFixed(order[0], allowedDecimal),
    volume: order[1],
    username: order[3]
  }));

  return book;
};

export const normalizeP2PTradeHistory = (trades, market, limit = null) => {
  const { base, quote } = getBaseAndQuoteCurrencyFromP2PMarket(market);
  const allowedDecimal = base && quote && getQuotePrecision(base, quote);
  const allowedBaseDecimal = base && getBasePrecision(base);
  const data = [];
  let prevTrade;
  for (let ti = trades.length - 1; ti >= 0; ti--) {
    const { id, createdAt, volume, price } = trades[ti];
    let refValue = 1;
    if (prevTrade) {
      const parsedCurrentPrice = new Big(price);
      if (parsedCurrentPrice.eq(prevTrade.price)) {
        refValue = prevTrade.refValue;
      } else {
        refValue = parsedCurrentPrice.gt(prevTrade.price) ? 1 : -1;
      }
    } else {
      refValue = 1;
    }

    const created_at = new Date(createdAt);
    data.unshift({
      id,
      refValue,
      created_at,
      price: toFixed(new Big(price), allowedDecimal),
      volume: toFixed(new Big(volume), allowedBaseDecimal),
      time: format(created_at, 'HH:mm:ss')
    });

    if (limit && data.length > limit) {
      data.pop();
    }
    prevTrade = data[0];
  }
  return data;
};

export const getBaseAndQuoteCurrencyFromP2PMarket = marketType => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.p2pMarkets) {
    const selectedMarket = config.p2pMarkets[marketType];
    return (
      selectedMarket && {
        quote: selectedMarket.quoteMarket,
        base: selectedMarket.baseMarket
      }
    );
  }
  return null;
};

export const getP2PMarket = marketType => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.p2pMarkets) {
    return config.p2pMarkets[marketType];
  }
  return undefined;
};

export const getDefaultP2PMarket = () => {
  const config = getJSONObjectFromLocalStorage('config');
  return (
    config &&
    config.p2pMarkets &&
    config.defaultP2PMarket &&
    config.p2pMarkets[config.defaultP2PMarket]
  );
};
export const getDefaultP2PMarketRoute = () => {
  const defaultP2PMarket = getDefaultP2PMarket();
  return (
    defaultP2PMarket &&
    joinMarketWithSeparator(
      defaultP2PMarket.baseMarket,
      defaultP2PMarket.quoteMarket,
      '-'
    )
  );
};

export const getP2PFees = (side, market) => {
  const config = getJSONObjectFromLocalStorage('config');
  const type = side === SIDE.BUY ? 'bid' : 'ask';
  const multiplier =
    config &&
    config.p2pMarkets &&
    market &&
    config.p2pMarkets[market] &&
    config.p2pMarkets[market].fee &&
    config.p2pMarkets[market].fee[type];
  return EMPTY_VALUES.indexOf(multiplier) === -1 ? multiplier * 100 : 0;
};

export const getP2PTax = market => {
  const config = getJSONObjectFromLocalStorage('config');
  const multiplier = market && config?.p2pMarkets?.[market]?.fee?.tds;
  return EMPTY_VALUES.indexOf(multiplier) === -1 ? multiplier : 0;
};
