import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import { KYC_VERIFICATION_STATES } from '../constants/Core';

import { normalizeVerificationStages } from '../utils/normalizer/userProfile';

const initialState = fromJS([]);

export default function(state = initialState, action) {
  switch (action.type) {
    case types.VERIFICATION_STAGES_INIT:
    case types.VERIFICATION_STAGES_UPDATE:
      return state.merge(normalizeVerificationStages(action.data));

    case types.RESEND_EMAIL_INIT:
      return state.update(
        state.findIndex(item => item.get('name') === 'email'),
        item =>
          item.merge({
            isResending: true,
            isResendingSuccess: false,
            isResendingError: false,
            signupOtpRequestData: null,
            resendDataError: null
          })
      );

    case types.RESEND_EMAIL_SUCCESS:
      return state.update(
        state.findIndex(item => item.get('name') === 'email'),
        item =>
          item.merge({
            isResending: false,
            isResendingSuccess: true,
            isResendingError: false,
            signupOtpRequestData: action.data,
            resendDataError: null
          })
      );

    case types.RESEND_EMAIL_ERROR:
      return state.update(
        state.findIndex(item => item.get('name') === 'email'),
        item =>
          item.merge({
            isResending: false,
            isResendingSuccess: false,
            isResendingError: true,
            signupOtpRequestData: null,
            resendDataError: action.error
          })
      );

    case types.SIGNUP_OTP_REQUEST_INIT:
      return state.update(
        state.findIndex(item => item.get('name') === 'email'),
        item =>
          item.merge({
            isSignOtpRequestSending: true,
            isSignOtpRequestSendingError: false,
            signupOtpRequestData: null,
            signUpOtpRequestError: null
          })
      );

    case types.SIGNUP_OTP_REQUEST_SUCCESS:
      return state.update(
        state.findIndex(item => item.get('name') === 'email'),
        item =>
          item.merge({
            isSignOtpRequestSending: false,
            isSignOtpRequestSendingError: false,
            signupOtpRequestData: action.data,
            signUpOtpRequestError: null
          })
      );

    case types.SIGNUP_OTP_REQUEST_ERROR:
      return state.update(
        state.findIndex(item => item.get('name') === 'email'),
        item =>
          item.merge({
            isSignOtpRequestSending: false,
            isSignOtpRequestSendingError: true,
            signupOtpRequestData: null,
            signUpOtpRequestError: action.error
          })
      );

    case types.SUBMIT_OTP_INIT:
      return state.update(
        state.findIndex(item => item.get('name') === 'twofa'),
        item =>
          item.merge({
            isSubmittingOTP: true,
            isSubmittingOTPError: false,
            submitOTPData: null,
            submitOTPDataError: null
          })
      );

    case types.SUBMIT_OTP_SUCCESS:
      return state.update(
        state.findIndex(item => item.get('name') === 'twofa'),
        item =>
          item.merge({
            isSubmittingOTP: false,
            isSubmittingOTPError: false,
            submitOTPData: action.data,
            submitOTPDataError: null
          })
      );

    case types.SUBMIT_OTP_ERROR:
      return state.update(
        state.findIndex(item => item.get('name') === 'twofa'),
        item =>
          item.merge({
            isSubmittingOTP: false,
            isSubmittingOTPError: true,
            submitOTPData: null,
            submitOTPDataError: action.error
          })
      );

    case types.GET_KYC_INIT:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isFetching: true,
            isFetchingError: false,
            data: action.data,
            error: null
          })
      );

    case types.GET_KYC_SUCCESS:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isFetching: false,
            isFetchingError: false,
            data: action.data,
            error: null
          })
      );

    case types.GET_KYC_ERROR:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isFetching: false,
            isFetchingError: true,
            data: null,
            error: action.error
          })
      );

    case types.RESET_SUBMIT_KYC:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isSubmitting: false,
            isSubmittingError: false,
            message: null,
            error: null
          })
      );
    case types.SUBMIT_KYC_INIT:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isSubmitting: true,
            isSubmittingError: false,
            message: null,
            error: null
          })
      );

    case types.SUBMIT_KYC_SUCCESS:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isSubmitting: false,
            isSubmittingError: false,
            message: action.data,
            error: null
          })
      );

    case types.SUBMIT_KYC_ERROR:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item =>
          item.merge({
            isSubmitting: false,
            isSubmittingError: true,
            message: null,
            error: action.error
          })
      );

    case types.RESTART_KYC_SUBMISSION:
      return state.update(
        state.findIndex(item => item.get('name') === 'kyc'),
        item => item.set('status', KYC_VERIFICATION_STATES.UNVERIFIED)
      );
    default:
      return state;
  }
}
