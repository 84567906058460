import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';

const initialState = fromJS({
  accounts: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  history: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SUBACCOUNT_ACCOUNTS_INIT:
      return state.mergeIn(['accounts'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_SUBACCOUNT_ACCOUNTS_SUCCESS:
      return state.mergeIn(['accounts'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_SUBACCOUNT_ACCOUNTS_ERROR:
      return state.mergeIn(['accounts'], {
        isFetching: false,
        isFetchingError: true,
        error: action.message
      });
    case types.ACTION_SUBACCOUNT_SUCCESS: {
      const index = state.getIn(['accounts', 'data']).findKey(item => {
        return action.data.subAccountEmail === item.get('email');
      });
      const newItem = state.getIn(['accounts', 'data', index]);
      return state.mergeIn(['accounts', 'data', index], {
        ...newItem,
        action:
          action.data.sub_account_action.toLowerCase() === 'freeze'
            ? 'unfreeze'
            : 'freeze'
      });
    }
    case types.CREATE_SUBACCOUNT_SUCCESS: {
      const size = state.getIn(['accounts', 'data'])?.size || 0;
      return state.mergeIn(['accounts', 'data', size], {
        action: 'Freeze',
        balance: [],
        portfolio: 0,
        created_at: new Date(),
        email: action.data.subAccountEmail,
        emailVerification: 'Completed',
        status: 'Active'
      });
    }
    case types.FETCH_SUBACCOUNT_TRANSFER_HISTORY_INIT:
      return state.mergeIn(['history'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_SUBACCOUNT_TRANSFER_HISTORY_SUCCESS: {
      const currentData = action.loadMore
        ? state.getIn(['history', 'data']) || []
        : [];
      return state.mergeIn(['history'], {
        isFetching: false,
        isFetchingError: false,
        data: [...currentData, ...action.data],
        isPageEnd: !action.data?.length || action.data.length < action.limit
      });
    }
    case types.FETCH_SUBACCOUNT_TRANSFER_HISTORY_ERROR:
      return state.mergeIn(['history'], {
        isFetching: false,
        isFetchingError: true,
        error: action.message
      });
    default:
      return state;
  }
}
