const nodeEnv = process.env.REACT_APP_NODE_ENV;

/**
 * env: {
  *   MOBILE_PAGE_LINK: String - The link your app will open. Ex: 'wrx.page.link', // https://firebase.google.com/docs/dynamic-links/create-manually
      MOBILE_APP_IBI: String - The bundle ID of the iOS app to use to open the link. Ex: 'com.wrx.wazirx.stage',
      MOBILE_APP_APN: String - The package name of the Android app to use to open the link Ex: 'com.wrx.wazirx.stage',
      MOBILE_APP_ISI: String - Your app's App Store ID. Ex: '962194608'
 * }
 * 
 *  */
const config = {
  dev: {
    DOMAIN_HOST: 'https://wazirx-dev.hellopye.com',
    API_HOST: 'https://api-dev.hellopye.com',
    FALCON_API_HOST: 'https://api-dev.hellopye.com',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXSTGW836h$bvak;fghPOeH2e4793467f08LdiGygh;k&#r28jRRTu0365gdi92t',
    PUSHER_KEY: 'a0ebe295eae33e28d280',
    MIXPANEL_TOKEN: 'ae78cf67b3dab74beb262bb9bcd496a4',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  fdev: {
    DOMAIN_HOST: 'https://frontend-dev.hellopye.com',
    API_HOST: 'https://frontend-dev.hellopye.com',
    FALCON_API_HOST: 'https://frontend-dev.hellopye.com',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXSTGW836h$bvak;fghPOeH2e4793467f08LdiGygh;k&#r28jRRTu0365gdi92t',
    PUSHER_KEY: '7151755d4fbe93479988',
    MIXPANEL_TOKEN: 'ae78cf67b3dab74beb262bb9bcd496a4',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  betaqa: {
    DOMAIN_HOST: 'https://beta.wazirx.hellopye.com',
    API_HOST: 'https://api.hellopye.com',
    FALCON_API_HOST: 'https://api.hellopye.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXSTGW836h$bvak;fghPOeH2e4793467f08LdiGygh;k&#r28jRRTu0365gdi92t',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    PUSHER_KEY: '5a6d60eb3d1ba4a233dc',
    MIXPANEL_TOKEN: 'ae78cf67b3dab74beb262bb9bcd496a4',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  qa: {
    DOMAIN_HOST: 'https://wazirx.hellopye.com',
    API_HOST: 'https://api.hellopye.com',
    FALCON_API_HOST: 'https://api.hellopye.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXSTGW836h$bvak;fghPOeH2e4793467f08LdiGygh;k&#r28jRRTu0365gdi92t',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    PUSHER_KEY: '5a6d60eb3d1ba4a233dc',
    MIXPANEL_TOKEN: 'ae78cf67b3dab74beb262bb9bcd496a4',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  'qa-v2': {
    DOMAIN_HOST: 'https://web-qa.hellopye.com',
    API_HOST: 'https://api-qa.hellopye.com',
    FALCON_API_HOST: 'https://api-qa.hellopye.com',
    WEBSOCKET_API: 'wss://stream-qa.hellopye.com/stream',
    API_KEY:
      'WRXSTGW836h$bvak;fghPOeH2e4793467f08LdiGygh;k&#r28jRRTu0365gdi92t',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    PUSHER_KEY: '5a6d60eb3d1ba4a233dc',
    MIXPANEL_TOKEN: 'ae78cf67b3dab74beb262bb9bcd496a4',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608'
  },
  test: {
    DOMAIN_HOST: 'https://feature-oauth.dev-workflow.hellopye.com',
    API_HOST: 'https://api.hellopye.com',
    FALCON_API_HOST: 'https://api.hellopye.com',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXSTGW836h$bvak;fghPOeH2e4793467f08LdiGygh;k&#r28jRRTu0365gdi92t',
    PUSHER_KEY: '5a6d60eb3d1ba4a233dc',
    MIXPANEL_TOKEN: 'ae78cf67b3dab74beb262bb9bcd496a4',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  alpha: {
    DOMAIN_HOST: 'https://wazirx-alpha.hellopye.com',
    API_HOST: 'https://alpha.wazirx.com',
    FALCON_API_HOST: 'https://api.hellopye.com',
    STATUS_CONFIGS_API: 'https://p.hellopye.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXPRODWn5Kc36$#%WYjguL;1oUYnD9ijiIHE7bk3r78%3#mFHJdik3n1Uafgib98*GI',
    PUSHER_KEY: '47bd0a9591a05c2a66db',
    MIXPANEL_TOKEN: '1199112ea8d135b26364fe56e656bad2',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://5ed7787bc2174e638935aa54c95fc93c@o524464.ingest.sentry.io/5647065',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LeJEnUUAAAAAGn1xWmmGzvaZuUSV8ExakybGEWk',
    GA_TRACKING_KEY: 'GTM-MNR8T3L',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  beta: {
    DOMAIN_HOST: 'https://alpha-web.wazirx.com',
    API_HOST: 'https://x.wazirx.com',
    FALCON_API_HOST: 'https://x.wazirx.com',
    STATUS_CONFIGS_API: 'https://p.wazirx.com',
    WEBSOCKET_API: 'wss://stream.hellopye.com/stream',
    API_KEY:
      'WRXPRODWn5Kc36$#%WYjguL;1oUYnD9ijiIHE7bk3r78%3#mFHJdik3n1Uafgib98*GI',
    PUSHER_KEY: '47bd0a9591a05c2a66db',
    MIXPANEL_TOKEN: 'caa2a76f66aad84844a4ca230baf8113',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-poc',
    SENTRY_CONFIG:
      'https://3e5974a52b4f48639017341a349258fb@o524464.ingest.sentry.io/5636947',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LfnDXUUAAAAAAFwuBbkcfLWBxa1rlUikjzKwlhD',
    GA_TRACKING_KEY: 'GTM-T927P7H',
    MOBILE_PAGE_LINK: 'wrx.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx.stage',
    MOBILE_APP_APN: 'com.wrx.wazirx.stage',
    MOBILE_APP_ISI: '962194608',
    MOENGAGE_APP_ID: 'VNBZZWPGCXM71SBCEBWXP8N4',
    MOENGAGE_CLUSTER: 'DC_3'
  },
  prod: {
    DOMAIN_HOST: 'https://wazirx.com',
    API_HOST: 'https://x.wazirx.com',
    FALCON_API_HOST: 'https://x.wazirx.com',
    STATUS_CONFIGS_API: 'https://p.wazirx.com',
    WEBSOCKET_API: 'wss://stream-internal.wazirx.com/stream',
    API_KEY:
      'WRXPRODWn5Kc36$#%WYjguL;1oUYnD9ijiIHE7bk3r78%3#mFHJdik3n1Uafgib98*GI',
    PUSHER_KEY: '47bd0a9591a05c2a66db',
    MIXPANEL_TOKEN: 'caa2a76f66aad84844a4ca230baf8113',
    LOGROCKET_TOKEN: 'rz4nz4/wazirx-prod',
    SENTRY_CONFIG:
      'https://3e5974a52b4f48639017341a349258fb@o524464.ingest.sentry.io/5636947',
    SUPPORT_HOST: 'https://wazirx.zendesk.com',
    RECAPTCHA_SITE_KEY: '6LfnDXUUAAAAAAFwuBbkcfLWBxa1rlUikjzKwlhD',
    GA_TRACKING_KEY: 'GTM-T927P7H',
    MOBILE_PAGE_LINK: 'wazirxdeeplink.page.link',
    MOBILE_APP_IBI: 'com.wrx.wazirx',
    MOBILE_APP_APN: 'com.wrx.wazirx',
    MOBILE_APP_ISI: '1349082789',
    MOENGAGE_APP_ID: 'OELBGPK5LB592KMLMDQDMT59',
    MOENGAGE_CLUSTER: 'DC_3'
  }
};

export default config[nodeEnv];
