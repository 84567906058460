import React, { Suspense, lazy } from 'react';
import WebFont from 'webfontloader';

import { customLazy, getSubDomain } from '../utils/core';

const WazirX = lazy(() => customLazy(() => import('./App')));
const MoneyControl = lazy(() =>
  customLazy(() => import('../components/MoneyControl'))
);

const DEFAULT_APP = WazirX;
const DEFAULT_FONTS = 'Open Sans:300,400,600,700,800';
const DEFAULT_FONT_API = 'https://fonts.googleapis.com/css';

const AppContainer = ({ history }) => {
  let App = WazirX;
  let fontsToLoad = '';
  let fontAPI = '';
  try {
    const page = getSubDomain();
    switch (page) {
      case 'moneycontrol':
        fontsToLoad = 'Lato:wght@400;700;900';
        fontAPI = 'https://fonts.googleapis.com/css2';
        if (history.location.pathname === '/') {
          App = MoneyControl;
        }
        break;
      case 'wazirx':
      default:
        App = WazirX;
        fontsToLoad = 'Open Sans:300,400,600,700,800';
        fontAPI = 'https://fonts.googleapis.com/css';
        break;
    }
  } catch (e) {
    App = DEFAULT_APP;
    fontsToLoad = DEFAULT_FONTS;
    fontAPI = DEFAULT_FONT_API;
  }
  WebFont.load({
    google: {
      api: fontAPI,
      families: [fontsToLoad]
    }
  });
  return (
    <Suspense fallback={<></>}>
      <App />
    </Suspense>
  );
};

export default AppContainer;
